const SET_CLIENTBILLMASTER_LIST = '@clientbillmaster/set-clientbillmaster-list';
const SET_CLIENTBILLMASTER_DETAIL =
  '@clientbillmaster/set-clientbillmaster-detail';
const SET_CLIENTBILLMASTER_LIST_TOTAL =
  '@clientbillmaster/set-clientbillmaster-list-total';

export {
  SET_CLIENTBILLMASTER_LIST,
  SET_CLIENTBILLMASTER_DETAIL,
  SET_CLIENTBILLMASTER_LIST_TOTAL,
};
