import {LANGUAGE} from '../../../../utilities';
import {languageTypes} from '../../action-types';

const {SET_LANGUAGE} = languageTypes;

const initialState = {
  language: LANGUAGE[1],
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_LANGUAGE:
      return {...state, language: payload};
    default:
      return state;
  }
};

export default reducer;
