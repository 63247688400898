import {LayoutTemplate} from '../../components';
import {
  BillMasterFormPage,
  BillMasterPage,
  ChannelFormPage,
  ChannelPage,
  ChannelToProviderFormPage,
  ChannelToProviderPage,
  ClientFormPage,
  ClientPage,
  ClientUploadPage,
  DashboardPage,
  LoginPage,
  PageNotFound,
  PenaltyFormPage,
  PenaltyPage,
  PenaltyUploadPage,
  ProviderFormPage,
  ProviderPage,
  TransactionJournalDetailPage,
  TransactionJournalPage,
  UserFormPage,
  UsersPage,
  ClientBillMasterFormPage,
  ClientBillMasterPage,
  ClientBillMasterUploadPage,
  UploadPage,
  UploadDetailPage,
  ChannelJournalPage,
  ChannelDepositPage,
  BillDetailPage,
  BillPage,
} from '../../pages';

export const routeSources = [
  {component: LoginPage, path: '/', exact: true, key: 'LOGIN'},
  {component: LoginPage, path: '/login', exact: true, key: 'LOGIN'},
  {
    component: PageNotFound,
    path: '/404-not-found',
    exact: true,
    key: 'PAGENOTFOUND',
    title: '404 Page Not Found',
  },
  {
    layout: LayoutTemplate,
    path: '',
    child: [
      {
        component: DashboardPage,
        path: '/dashboard',
        exact: true,
        private: true,
        key: 'DASHBOARD',
      },
      {
        component: ProviderPage,
        path: '/provider',
        exact: true,
        private: true,
        key: 'PROVIDER',
        title: 'Provider',
      },
      {
        component: ProviderFormPage,
        path: '/provider/add',
        exact: true,
        private: true,
        key: 'PROVIDER',
        title: 'Provider',
      },
      {
        component: ProviderFormPage,
        path: '/provider/detail',
        exact: true,
        private: true,
        key: 'PROVIDER',
        title: 'Provider',
      },
      {
        component: ProviderFormPage,
        path: '/provider/edit',
        exact: true,
        private: true,
        key: 'PROVIDER',
        title: 'Provider',
      },
      {
        component: UsersPage,
        path: '/user',
        exact: true,
        private: true,
        key: 'USER',
        title: 'User',
      },
      {
        component: UserFormPage,
        path: '/user/add',
        exact: true,
        private: true,
        key: 'USER',
        title: 'User',
      },
      {
        component: UserFormPage,
        path: '/user/edit',
        exact: true,
        private: true,
        key: 'USER',
        title: 'User',
      },
      {
        component: UserFormPage,
        path: '/user/detail',
        exact: true,
        private: true,
        key: 'USER',
        title: 'User',
      },
      {
        component: BillMasterPage,
        path: '/bill-management/billmaster',
        exact: true,
        private: true,
        key: 'BILLMASTER',
        title: 'Bill Master',
      },
      {
        component: BillMasterFormPage,
        path: '/bill-management/billmaster/add',
        exact: true,
        private: true,
        key: 'BILLMASTER',
        title: 'Bill Master',
      },
      {
        component: BillMasterFormPage,
        path: '/bill-management/billmaster/edit',
        exact: true,
        private: true,
        key: 'BILLMASTER',
        title: 'Bill Master',
      },
      {
        component: BillMasterFormPage,
        path: '/bill-management/billmaster/approval',
        exact: true,
        private: true,
        key: 'BILLMASTER',
        title: 'Bill Master',
      },
      {
        component: BillMasterFormPage,
        path: '/bill-management/billmaster/detail',
        exact: true,
        private: true,
        key: 'BILLMASTER',
        title: 'Bill Master',
      },
      {
        component: ClientPage,
        path: '/client',
        exact: true,
        private: true,
        key: 'CLIENT',
        title: 'Client',
      },
      {
        component: ClientUploadPage,
        path: '/client/upload',
        exact: true,
        private: true,
        key: 'CLIENT',
        title: 'Client',
      },
      {
        component: ClientFormPage,
        path: '/client/add',
        exact: true,
        private: true,
        key: 'CLIENT',
        title: 'Client',
      },
      {
        component: ClientFormPage,
        path: '/client/edit',
        exact: true,
        private: true,
        key: 'CLIENT',
        title: 'Client',
      },
      {
        component: ClientFormPage,
        path: '/client/detail',
        exact: true,
        private: true,
        key: 'CLIENT',
        title: 'Client',
      },
      {
        component: ChannelPage,
        path: '/channel-management/channel',
        exact: true,
        private: true,
        key: 'CHANNEL',
        title: 'Channel',
      },
      {
        component: ChannelFormPage,
        path: '/channel-management/channel/add',
        exact: true,
        private: true,
        key: 'CHANNEL',
        title: 'Channel',
      },
      {
        component: ChannelFormPage,
        path: '/channel-management/channel/edit',
        exact: true,
        private: true,
        key: 'CHANNEL',
        title: 'Channel',
      },
      {
        component: ChannelFormPage,
        path: '/channel-management/channel/detail',
        exact: true,
        private: true,
        key: 'CHANNEL',
        title: 'Channel',
      },
      {
        component: ChannelToProviderPage,
        path: '/channel-management/channel-to-provider',
        exact: true,
        private: true,
        key: 'CHANNELXPROVIDER',
        title: 'Channel to Provider',
      },
      {
        component: ChannelToProviderFormPage,
        path: '/channel-management/channel-to-provider/add',
        exact: true,
        private: true,
        key: 'CHANNELXPROVIDER',
        title: 'Channel to Provider',
      },
      {
        component: ChannelToProviderFormPage,
        path: '/channel-management/channel-to-provider/edit',
        exact: true,
        private: true,
        key: 'CHANNELXPROVIDER',
        title: 'Channel to Provider',
      },
      {
        component: ChannelToProviderFormPage,
        path: '/channel-management/channel-to-provider/detail',
        exact: true,
        private: true,
        key: 'CHANNELXPROVIDER',
        title: 'Channel to Provider',
      },
      {
        component: PenaltyPage,
        path: '/penalty',
        exact: true,
        private: true,
        key: 'PENALTY',
        title: 'Penalty',
      },
      {
        component: PenaltyUploadPage,
        path: '/penalty/upload',
        exact: true,
        private: true,
        key: 'PENALTY',
        title: 'Penalty',
      },
      {
        component: PenaltyFormPage,
        path: '/penalty/add',
        exact: true,
        private: true,
        key: 'PENALTY',
        title: 'Penalty',
      },
      {
        component: PenaltyFormPage,
        path: '/penalty/edit',
        exact: true,
        private: true,
        key: 'PENALTY',
        title: 'Penalty',
      },
      {
        component: PenaltyFormPage,
        path: '/penalty/detail',
        exact: true,
        private: true,
        key: 'PENALTY',
        title: 'Penalty',
      },
      {
        component: TransactionJournalPage,
        path: '/journal',
        exact: true,
        private: true,
        key: 'JOURNAL',
        title: 'Transaction Journal',
      },
      {
        component: TransactionJournalDetailPage,
        path: '/journal/detail',
        exact: true,
        private: true,
        key: 'JOURNAL',
        title: 'Transaction Journal',
      },
      {
        component: ClientBillMasterPage,
        path: '/bill-management/clientbillmaster',
        exact: true,
        private: true,
        key: 'CLIENTBILLMASTER',
        title: 'Client Bill Master',
      },
      {
        component: ClientBillMasterUploadPage,
        path: '/bill-management/clientbillmaster/upload',
        exact: true,
        private: true,
        key: 'CLIENTBILLMASTER',
        title: 'Client Bill Master',
      },
      {
        component: ClientBillMasterFormPage,
        path: '/bill-management/clientbillmaster/add',
        exact: true,
        private: true,
        key: 'CLIENTBILLMASTER',
        title: 'Client Bill Master',
      },
      {
        component: ClientBillMasterFormPage,
        path: '/bill-management/clientbillmaster/edit',
        exact: true,
        private: true,
        key: 'CLIENTBILLMASTER',
        title: 'Client Bill Master',
      },
      {
        component: ClientBillMasterFormPage,
        path: '/bill-management/clientbillmaster/detail',
        exact: true,
        private: true,
        key: 'CLIENTBILLMASTER',
        title: 'Client Bill Master',
      },
      {
        component: UploadPage,
        path: '/upload',
        exact: true,
        private: true,
        key: 'UPLOAD',
        title: 'Upload',
      },
      {
        component: UploadDetailPage,
        path: '/upload/detail',
        exact: true,
        private: true,
        key: 'UPLOAD',
        title: 'Upload',
      },
      {
        component: BillPage,
        path: '/bill-management/bill',
        exact: true,
        private: true,
        key: 'BILL',
        title: 'Bill',
      },
      {
        component: BillDetailPage,
        path: '/bill-management/bill/detail',
        exact: true,
        private: true,
        key: 'BILL',
        title: 'Bill',
      },
      {
        component: ChannelJournalPage,
        path: '/channel-management/channel-journal',
        exact: true,
        private: true,
        key: 'CHANNELJOURNAL',
        title: 'Channel Journal',
      },
      {
        component: ChannelDepositPage,
        path: '/channel-management/channel/deposit',
        exact: true,
        private: true,
        key: 'CHANNEL',
        title: 'Channel',
      },
      {
        component: PageNotFound,
        path: '/404-not-found',
        private: true,
        exact: true,
        key: 'PAGENOTFOUND',
        title: '404 Page Not Found',
      },
    ],
  },
];
