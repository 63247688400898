import {EditFilled} from '@ant-design/icons';
import {Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {loadingActions, penaltyActions} from '../../redux/store/actions';
import {compare, history, thousandSeparator} from '../../utilities';

const {showLoadingTable, dismissLoadingTable} = loadingActions;
const {getListPenalty, setDetailPenalty} = penaltyActions;

const PenaltyPage = () => {
  const dispatch = useDispatch();
  const {listPenalty} = useSelector((state) => state.penalty);
  const {authData} = useSelector((state) => state.auth);
  const {t} = useTranslation('penaltyPage');
  useEffect(() => {
    dispatch(getListPenalty(null, showLoadingTable, dismissLoadingTable));
  }, []);
  const showButton = authData.roles === 'provider';
  const createColumns = () => {
    return [
      {
        title: t('billName'),
        dataIndex: 'bill_master_name',
        key: 'bill_master_name',
        width: '16%',
        sorter: (a, b) => compare(a, b, 'bill_master_name'),
      },
      {
        title: t('clientName'),
        dataIndex: 'clientName',
        key: 'clientName',
        width: '16%',
        sorter: (a, b) => compare(a, b, 'clientName'),
      },
      {
        title: t('amount'),
        dataIndex: 'amount',
        key: 'amount',
        width: '16%',
        align: 'right',
        render: (item) => thousandSeparator(item, 'Rp '),
        sorter: (a, b) => a.amount - b.amount,
      },
      {
        title: t('status'),
        dataIndex: 'is_paid',
        key: 'is_paid',
        filters: [
          {text: t('paid'), value: true},
          {text: t('unpaid'), value: false},
        ],
        width: '16%',

        onFilter: (value, record) => record.is_paid === value,
        render: (item) =>
          item ? (
            <p className="text-success">{t('paid')}</p>
          ) : (
            <p className="text-danger">{t('unpaid')}</p>
          ),
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        key: 'remarks',
        width: '19%',
        sorter: (a, b) => compare(a, b, 'remarks'),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '16%',
        render: (index, item) => {
          return (
            <Row justify="start">
              {showButton && !item.is_paid && (
                <Button
                  icon={<EditFilled />}
                  text={t('buttonEdit')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/penalty/edit',
                      state: {
                        type: 'EDIT',
                        key: 'PENALTY',
                        id: item.penalty_id,
                      },
                    });
                  }}
                />
              )}
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd={showButton}
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/penalty/add',
          params: {type: 'ADD', key: 'PENALTY'},
        }}
      />
      <Container>
        <CustomTable
          showButtonUpload={showButton}
          uploadPage="/penalty/upload"
          uploadPageParams={{key: 'PENALTY'}}
          placeholderSearch={t('placeholderSearch')}
          filterBy={['bill_master_name', 'amount', 'remarks', 'clientName']}
          createColumns={createColumns}
          dataSource={listPenalty}
        />
      </Container>
    </div>
  );
};

export default PenaltyPage;
