import {usersTypes} from '../../action-types';

const {SET_USERS_LIST, SET_USERS_DETAIL, SET_LIST_BANK} = usersTypes;

const initialState = {
  listUsers: [],
  detailUsers: {},
  listBank: [],
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_USERS_LIST:
      return {...state, listUsers: payload};
    case SET_USERS_DETAIL:
      return {...state, detailUsers: payload};
    case SET_LIST_BANK:
      return {...state, listBank: payload};
    default:
      return state;
  }
};

export default reducer;
