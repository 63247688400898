import {dashboardTypes} from '../../action-types';
import Services from '../../../../services';
import {message} from 'antd';

const {SET_IFRAME_URL, SET_BALANCE, SET_WITHDRAWAL_FEE} = dashboardTypes;

const setIframeUrl = (payload) => ({
  type: SET_IFRAME_URL,
  payload,
});

const setBalance = (payload) => ({
  type: SET_BALANCE,
  payload,
});

const setWithdrawalFee = (payload) => ({
  type: SET_WITHDRAWAL_FEE,
  payload,
});

const getIframeUrl = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('report/dashboard')
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values?.iframeUrl);
          dispatch(setIframeUrl(res.data.values.iframeUrl));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};
const getBalance = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('withdraw/balance')
      .then((res) => {
        if (res.data.values) {
          console.log(res.data.values);
          resolve(res.data.values);
          dispatch(setBalance(res.data.values));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        dispatch(setBalance(''));
        dispatch(setWithdrawalFee(''));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const withdrawBalance = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('withdraw', request)
      .then((res) => {
        if (res.data.values) {
          message.success('Withdraw balance successfully');
          dispatch(getBalance());
          resolve(res.data.values);
          dispatch(setBalance(res.data.values));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

export {getIframeUrl, getBalance, withdrawBalance};
