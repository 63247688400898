import {Col, message, Row, Form, Input} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Button,
  Container,
  CustomForm,
  CustomInput,
  PageTitle,
} from '../../components';
import {
  loadingActions,
  providerActions,
  usersActions,
} from '../../redux/store/actions';
import {history} from '../../utilities';

const {
  createProvider,
  getFile,
  setDetailProvider,
  updateProvider,
  getDetailProvider,
} = providerActions;
const {getListUsers} = usersActions;
const {dismissLoading, showLoading} = loadingActions;

const ProviderFormPage = () => {
  const dispatch = useDispatch();
  const {detailProvider} = useSelector((state) => state.provider);
  const {authData} = useSelector((state) => state.auth);
  const {listUsers} = useSelector((state) => state.users);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const {t} = useTranslation('providerFormPage');
  useEffect(() => {
    if (location?.state) {
      if (location.state?.type !== 'ADD') {
        const request = {id: location?.state?.id, role: authData.roles};
        dispatch(getDetailProvider(request, showLoading, dismissLoading));
      }
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-provider',
          });
          break;
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-provider',
            disabled: true,
          });
          break;
        case 'EDIT':
          setPageType({
            title: t('editTitle'),
            type: 'EDIT',
            form: 'edit-provider',
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    } else {
      history.push('/provider');
    }
    if (authData.roles === 'super_admin') {
      getUser();
    }
    return () => {
      dispatch(setDetailProvider({}));
    };
  }, []);
  const getUser = () => {
    setLoadingUser(true);
    dispatch(getListUsers()).finally(() => {
      setLoadingUser(false);
    });
  };
  const onFinish = (values) => {
    if (pageType.type === 'ADD') {
      setLoading(true);
      dispatch(createProvider(values))
        .then(() => {
          message.success(t('success'));
          history.push('/provider');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      dispatch(
        updateProvider({provider_id: detailProvider.provider_id, ...values}),
      )
        .then(() => {
          message.success(t('editSuccess', {providerName: values.name}));
          history.push('/provider');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onClickShowPreview = (fileName) => {
    dispatch(getFile(fileName, showLoading, dismissLoading));
  };
  const userListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.user_id,
      label: item.name,
    }));
    return result;
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm
              name={pageType?.form}
              defaultValues={detailProvider}
              onSubmit={onFinish}
              onSubmitFailed={onFinishFailed}>
              <Form.Item
                name="name"
                label={t('name')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {authData.roles === 'super_admin' && (
                <Form.Item
                  name="userId"
                  label={t('user')}
                  labelCol={{span: 6}}
                  labelAlign="left"
                  rules={[
                    {
                      required: pageType?.type !== 'DETAIL',
                      message: t('required'),
                    },
                    {
                      whitespace: true,
                      message: t('required'),
                    },
                  ]}>
                  <CustomInput
                    disabled={pageType?.type !== 'ADD'}
                    name="userId"
                    type="select"
                    source={userListMapper(listUsers)}
                    loading={loadingUser}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="description"
                label={t('description')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input.TextArea
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {/* <Form.Item
                name="contractFile"
                label={t('contractFile')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  type="upload-file"
                  name="contractFile"
                  requestUploadName="contract"
                  urlUpload="file/contract"
                  showPreview={
                    pageType?.type !== 'ADD' &&
                    authData.roles === 'super_admin' &&
                    onClickShowPreview
                  }
                />
              </Form.Item> */}
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProviderFormPage;
