import {Col, Form, Input, message, Row} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Button, Container, CustomInput, PageTitle} from '../../components';
import {channelActions, loadingActions} from '../../redux/store/actions';
import {history} from '../../utilities';

const {depositChannel} = channelActions;
const {showLoadingButton, dismissLoadingButton} = loadingActions;

const ChannelDepositPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {channelId, list} = location?.state;
  const {t} = useTranslation('channelDepositPage');
  const onFinish = (values) => {
    dispatch(
      depositChannel(values, showLoadingButton, dismissLoadingButton),
    ).then(() => {
      message.success(t('success'));
      history.push('/channel-management/channel');
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const channelListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.channel_id,
      label: item.name,
    }));
    return result;
  };
  return (
    <div>
      <PageTitle title={t('title')} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <Form
              initialValues={{channelId}}
              name="channel-deposit"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                name="channelId"
                label={t('channelName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={true}
                  name="channelId"
                  type="select"
                  source={channelListMapper(list)}
                />
              </Form.Item>
              <Form.Item
                name="amount"
                label={t('amount')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                  () => ({
                    validator(_, value) {
                      if (value <= 0) {
                        return Promise.reject(t('minAmount'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <CustomInput type="money" />
              </Form.Item>
              <Form.Item
                name="reference"
                label={t('reference')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input style={{borderRadius: 5}} />
              </Form.Item>
              <Form.Item
                name="remarks"
                label={t('remarks')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input.TextArea style={{borderRadius: 5}} />
              </Form.Item>
              <Form.Item wrapperCol={{span: 4, offset: 20}}>
                <Button
                  text={t('submit')}
                  block
                  type="primary"
                  htmlType="submit"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChannelDepositPage;
