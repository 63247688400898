import {DeleteFilled} from '@ant-design/icons';
import {message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {
  channelToProviderActions,
  loadingActions,
} from '../../redux/store/actions';
import {compare} from '../../utilities';

const {
  showLoading,
  dismissLoading,
  showLoadingTable,
  dismissLoadingTable,
} = loadingActions;
const {
  getListChannelToProvider,
  deleteChannelToProvider,
} = channelToProviderActions;

const ChannelToProviderPage = () => {
  const dispatch = useDispatch();
  const {listChannelToProvider} = useSelector(
    (state) => state.channelToProvider,
  );
  const {t} = useTranslation('channelToProviderPage');
  useEffect(() => {
    dispatch(
      getListChannelToProvider(null, showLoadingTable, dismissLoadingTable),
    );
  }, []);
  const onClickDelete = (req) => {
    dispatch(deleteChannelToProvider(req, showLoading, null))
      .then((res) => {
        message.success(t('deleteSuccess', {name: req.name}));
        dispatch(getListChannelToProvider(null, null, dismissLoading));
      })
      .catch((err) => {
        message.error(t('deleteFailed', {name: req.name}));
      });
  };
  const createColumns = () => {
    return [
      {
        title: t('channelName'),
        dataIndex: 'channel_name',
        key: 'channel_name',
        sorter: (a, b) => compare(a, b, 'channel_name'),
      },
      {
        title: t('providerName'),
        dataIndex: 'provider_name',
        key: 'provider_name',
        sorter: (a, b) => compare(a, b, 'provider_name'),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '20%',
        render: (index, item) => {
          return (
            <Row justify="start">
              <Popconfirm
                title={t('deleteConfirm')}
                okText={t('yes')}
                onConfirm={() => onClickDelete(item)}
                cancelText={t('no')}>
                <Button
                  icon={<DeleteFilled />}
                  text={t('buttonDelete')}
                  type="light"
                  rounded
                  size="small"
                />
              </Popconfirm>
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/channel-management/channel-to-provider/add',
          params: {type: 'ADD', key: 'USER'},
        }}
      />
      <Container>
        <CustomTable
          placeholderSearch={t('placeholderSearch')}
          filterBy={['channel_name', 'provider_name']}
          createColumns={createColumns}
          dataSource={listChannelToProvider}
        />
      </Container>
    </div>
  );
};

export default ChannelToProviderPage;
