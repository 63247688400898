import {billMasterTypes} from '../../action-types';
import Moment from 'moment';

const {
  SET_BILLMASTER_LIST,
  SET_BILLMASTER_DETAIL,
  SET_BILLMASTER_LIST_TOTAL,
} = billMasterTypes;

const initialState = {
  listBillMaster: [],
  detailBillMaster: {
    billDate: Moment(),
    billDueDate: Moment(),
  },
  totalBillMaster: '',
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_BILLMASTER_LIST:
      return {...state, listBillMaster: payload};
    case SET_BILLMASTER_DETAIL:
      return {...state, detailBillMaster: payload};
    case SET_BILLMASTER_LIST_TOTAL:
      return {...state, totalBillMaster: payload};
    default:
      return state;
  }
};

export default reducer;
