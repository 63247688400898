import {billTypes} from '../../action-types';
import Services from '../../../../services';
import Moment from 'moment';
import {message} from 'antd';
import {isEmptyObject} from '../../../../utilities';

const {SET_BILL_LIST, SET_BILL_DETAIL, SET_BILL_LIST_TOTAL} = billTypes;

const setBillList = (payload) => ({
  type: SET_BILL_LIST,
  payload,
});

const setBillTotal = (payload) => ({
  type: SET_BILL_LIST_TOTAL,
  payload,
});

const getListBill = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`bill`, {...request, size: 10000})
      .then((res) => {
        if (res.data.values) {
          const result = res.data.values.data.map((item) => ({
            ...item,
            client_name: item.client_data.name,
            client_mobile: item.client_data.mobile,
            client_address: item.client_data.address,
          }));
          resolve(result);
          dispatch(setBillList(result));
          dispatch(setBillTotal(res.data.values.totalData));
        } else {
          dispatch(setBillList([]));
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        dispatch(setBillList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailBill = (data, role) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (!isEmptyObject(data)) {
      const result = {
        ...data,
        bill_date: Moment(data.bill_date),
        bill_due_date: Moment(data.bill_due_date),
        admin_shares:
          role === 'super_admin'
            ? data.admin_shares
            : data?.admin_shares?.map((item) => ({
                ...item,
                user_id: item.name,
              })),
        client_name: data?.client_data?.name,
        client_mobile: data?.client_data?.mobile,
        client_address: data?.client_data?.address,
      };
      resolve(result);
      dispatch({
        type: SET_BILL_DETAIL,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_BILL_DETAIL,
        payload: {},
      });
    }
  });
};

const getDetailBill = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`bill/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailBill(data, request.role));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const payBill = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post(`bill/${request.bill_id}/pay`, request)
      .then((res) => {
        if (res.data.values) {
          message.success('Payment Successful');
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};
export {getListBill, setDetailBill, payBill, getDetailBill};
