import {
  DeleteFilled,
  DollarCircleOutlined,
  EditFilled,
  EyeOutlined,
} from '@ant-design/icons';
import {Col, message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {channelActions, loadingActions} from '../../redux/store/actions';
import {compare, history, thousandSeparator} from '../../utilities';

const {
  showLoading,
  dismissLoading,
  showLoadingTable,
  dismissLoadingTable,
} = loadingActions;
const {getListChannel, deleteChannel, setDetailChannel} = channelActions;

const ChannelPage = () => {
  const dispatch = useDispatch();
  const {listChannel} = useSelector((state) => state.channel);
  const {t} = useTranslation('channelPage');
  useEffect(() => {
    dispatch(getListChannel(null, showLoadingTable, dismissLoadingTable));
  }, []);
  const onClickDelete = (req) => {
    dispatch(deleteChannel(req, showLoading, null))
      .then((res) => {
        message.success(t('deleteSuccess', {name: req.name}));
        dispatch(getListChannel(null, null, dismissLoading));
      })
      .catch((err) => {
        dispatch(dismissLoading());
      });
  };
  const createColumns = () => {
    return [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => compare(a, b, 'name'),
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => compare(a, b, 'description'),
      },
      {
        title: t('settlementType'),
        dataIndex: 'settlement_type',
        key: 'settlement_type',
        sorter: (a, b) => compare(a, b, 'settlement_type'),
      },
      {
        title: t('balance'),
        dataIndex: 'balance',
        key: 'balance',
        align: 'right',
        width: '15%',
        render: (item) => thousandSeparator(item, 'Rp '),
        sorter: (a, b) => a.balance - b.balance,
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '40%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={6}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/channel-management/channel/detail',
                      state: {
                        type: 'DETAIL',
                        key: 'CHANNEL',
                        id: item.channel_id,
                      },
                    });
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={24} xl={6}>
                <Button
                  icon={<EditFilled />}
                  text={t('buttonEdit')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/channel-management/channel/edit',
                      state: {
                        type: 'EDIT',
                        key: 'CHANNEL',
                        id: item.channel_id,
                      },
                    });
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={24} xl={6}>
                <Popconfirm
                  title={t('deleteConfirm', {name: item.name})}
                  okText={t('yes')}
                  onConfirm={() => onClickDelete(item)}
                  cancelText={t('no')}>
                  <Button
                    icon={<DeleteFilled />}
                    text={t('buttonDelete')}
                    type="light"
                    rounded
                    size="small"
                  />
                </Popconfirm>
              </Col>
              <Col xs={24} md={24} lg={24} xl={6}>
                <Button
                  icon={<DollarCircleOutlined />}
                  text={t('buttonDeposit')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/channel-management/channel/deposit',
                      state: {
                        key: 'CHANNEL',
                        channelId: item.channel_id,
                        list: listChannel,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/channel-management/channel/add',
          params: {type: 'ADD', key: 'CHANNEL'},
        }}
      />
      <Container>
        <CustomTable
          placeholderSearch={t('placeholderSearch')}
          filterBy={['name', 'description', 'balance', 'settlement_type']}
          createColumns={createColumns}
          dataSource={listChannel}
        />
      </Container>
    </div>
  );
};

export default ChannelPage;
