import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {combineReducers, createStore, applyMiddleware} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import {
  authReducer,
  layoutReducer,
  loadingReducer,
  providerReducer,
  usersReducer,
  billMasterReducer,
  clientReducer,
  dashboardReducer,
  channelReducer,
  channelToProviderReducer,
  penaltyReducer,
  transactionJournalReducer,
  clientBillMasterReducer,
  uploadReducer,
  channelJournalReducer,
  billReducer,
  languageReducer,
  tableReducer,
} from './reducers';

const appReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  loading: loadingReducer,
  provider: providerReducer,
  users: usersReducer,
  billMaster: billMasterReducer,
  client: clientReducer,
  dashboard: dashboardReducer,
  channel: channelReducer,
  channelToProvider: channelToProviderReducer,
  penalty: penaltyReducer,
  transactionJournal: transactionJournalReducer,
  clientBillMaster: clientBillMasterReducer,
  upload: uploadReducer,
  channelJournal: channelJournalReducer,
  bill: billReducer,
  language: languageReducer,
  table: tableReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export const configureStore = () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'language', 'table'],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, applyMiddleware(thunk));
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
