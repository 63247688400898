import {Col, DatePicker, Form, Input, Row} from 'antd';
import Moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Container,
  CustomInput,
  CustomTable,
  PageTitle,
  CustomForm,
} from '../../components';
import {
  columnsMapper,
  filterSearchMapper,
} from '../../redux/store/actions/upload/mapper';
import {uploadActions, usersActions} from '../../redux/store/actions';
import {history} from '../../utilities';
import {getDetailUpload} from '../../redux/store/actions/upload';
import {dismissLoading, showLoading} from '../../redux/store/actions/loading';

const {setUploadDetail} = uploadActions;
const {getListUsers} = usersActions;

const UploadDetailPage = () => {
  const dispatch = useDispatch();
  const {detailUpload} = useSelector((state) => state.upload);
  const {listUsers} = useSelector((state) => state.users);
  const {authData} = useSelector((state) => state.auth);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingUser, setLoadingUser] = useState(false);
  const {t} = useTranslation('uploadDetailPage');
  useEffect(() => {
    if (location?.state?.id) {
      dispatch(
        getDetailUpload(
          {id: location.state.id},
          t,
          showLoading,
          dismissLoading,
        ),
      );
      setPageType({
        title: t('title'),
        type: 'DETAIL',
        form: 'detail-upload',
        disabled: true,
      });
    } else {
      history.push('/upload');
    }
    if (authData.roles === 'super_admin') {
      getUser();
    }
    return () => {
      dispatch(setUploadDetail());
    };
  }, [location.state, t]);
  const getUser = () => {
    setLoadingUser(true);
    dispatch(getListUsers()).finally(() => {
      setLoadingUser(false);
    });
  };
  const userListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.user_id,
      label: item.name,
    }));
    return result;
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm name={pageType?.form} defaultValues={detailUpload}>
              <Form.Item
                name="filename"
                label={t('fileName')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {authData.roles === 'super_admin' && (
                <Form.Item
                  name="user_id"
                  label={t('user')}
                  labelCol={{span: 6}}
                  labelAlign="left">
                  <CustomInput
                    disabled={pageType?.disabled}
                    name="user_id"
                    type="select"
                    source={userListMapper(listUsers)}
                    loading={loadingUser}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="type"
                label={t('type')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {detailUpload?.is_processed && (
                <Form.Item
                  name="process_date"
                  label={t('processDate')}
                  labelCol={{span: 6}}
                  labelAlign="left"
                  rules={[
                    {
                      required: pageType?.type !== 'DETAIL',
                      message: t('required'),
                    },
                  ]}>
                  <DatePicker
                    defaultValue={Moment()}
                    disabled={pageType?.disabled}
                    style={{borderRadius: 5, width: '100%'}}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="upload_date"
                label={t('uploadDate')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                ]}>
                <DatePicker
                  defaultValue={Moment()}
                  disabled={pageType?.disabled}
                  style={{borderRadius: 5, width: '100%'}}
                />
              </Form.Item>
              <Form.Item
                name="status"
                label={t('status')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="data"
                label={t('data')}
                labelCol={{span: 24}}
                labelAlign="left">
                <CustomTable
                  filterBy={filterSearchMapper(
                    detailUpload?.data && detailUpload.data[0],
                  )}
                  createColumns={columnsMapper(
                    detailUpload?.data && detailUpload.data[0],
                  )}
                  dataSource={detailUpload?.data}
                />
              </Form.Item>
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UploadDetailPage;
