import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {colors} from '../../../../utilities';

const columnsMapper = (columns) => {
  const returnResult = () => {
    const result = [];
    if (columns) {
      Object.keys(columns)?.forEach((item) => {
        if (
          item !== 'upload_id' &&
          item !== 'upload_detail_id' &&
          item !== 'rows'
        ) {
          if (item === 'is_valid' || item === 'is_processed') {
            result.push({
              title: item,
              key: item,
              dataIndex: item,
              align: 'center',
              width: `${100 / (Object.keys(columns).length - 3)}%`,
              render: (item) =>
                item ? (
                  <CheckCircleOutlined
                    style={{fontSize: 20, color: colors.secondary}}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{fontSize: 20, color: colors.danger}}
                  />
                ),
            });
          } else {
            result.push({
              title: item,
              key: item,
              dataIndex: item,
              width: `${100 / (Object.keys(columns).length - 3)}%`,
            });
          }
        }
      });
    }
    return result;
  };
  return returnResult;
};

const filterSearchMapper = (columns) => {
  let result = [];
  if (columns) {
    result = Object.keys(columns)?.filter((item) => {
      if (
        item !== 'upload_id' &&
        item !== 'upload_detail_id' &&
        item !== 'rows' &&
        item !== 'is_valid' &&
        item !== 'is_processed'
      ) {
        return item;
      }
    });
  }
  return result;
};

export {columnsMapper, filterSearchMapper};