import {Col, Form, Input, message, Modal, Popconfirm, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Button,
  Container,
  CustomForm,
  CustomInput,
  PageTitle,
} from '../../components';
import {channelActions, loadingActions} from '../../redux/store/actions';
import {history} from '../../utilities';

const {
  createChannel,
  setDetailChannel,
  updateChannel,
  regenerateSecretKey,
  getDetailChannel,
} = channelActions;
const {dismissLoading, showLoading} = loadingActions;

const ChannelFormPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {detailChannel} = useSelector((state) => state.channel);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [secretKey] = useState('');
  const {t} = useTranslation('channelFormPage');
  useEffect(() => {
    if (location?.state) {
      if (location.state.type !== 'ADD') {
        const request = {id: location?.state?.id};
        dispatch(getDetailChannel(request, showLoading, dismissLoading));
      }
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-channel',
          });
          break;
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-channel',
            disabled: true,
          });
          break;
        case 'EDIT':
          setPageType({
            title: t('editTitle'),
            type: 'EDIT',
            form: 'edit-channel',
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    } else {
      history.push('/channel-management/channel');
    }
    return () => {
      dispatch(setDetailChannel({}));
    };
  }, [location.state, t]);
  const onFinish = (values) => {
    if (pageType.type === 'ADD') {
      setLoading(true);
      dispatch(createChannel(values))
        .then((res) => {
          message.success(t('success'));
          history.push('/channel-management/channel');
          Swal.fire({
            icon: 'success',
            title: t('success'),
            html: `
              ${t('successCreateMessage1')}<br />
              <b>${res.secret}</b><br />
              ${t('successCreateMessage2')}`,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      delete values['secret'];
      setLoading(true);
      dispatch(
        updateChannel({
          channel_id: detailChannel.channel_id,
          ...values,
        }),
      )
        .then(() => {
          message.success(t('editSuccess'));
          history.push('/channel-management/channel');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const regenerate = (id) => {
    setLoadingGenerate(true);
    dispatch(regenerateSecretKey(id))
      .then((res) => {
        Swal.fire({
          icon: 'success',
          title: t('successGenerate'),
          html: `
            ${t('successCreateMessage1')}<br />
            <b>${res.secret}</b><br />
            ${t('successCreateMessage2')}`,
        });
      })
      .finally(() => {
        setLoadingGenerate(false);
      });
  };
  return (
    <div>
      <Modal
        visible={visible}
        footer={[
          <Button type="info" text="Ok" onClick={() => setVisible(false)} />,
        ]}>
        <Row justify="center" align="middle">
          {t('successCreateMessage1')}
        </Row>
        <Row justify="center" align="middle">
          {secretKey}
        </Row>
        <Row justify="center" align="middle">
          {t('successCreateMessage2')}
        </Row>
      </Modal>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm
              name={pageType?.form}
              defaultValues={detailChannel}
              onSubmit={onFinish}
              onSubmitFailed={onFinishFailed}>
              <Form.Item
                name="name"
                label={t('name')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="identifier"
                label={t('identifier')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {pageType.type !== 'ADD' && pageType.type !== 'EDIT' && (
                <Form.Item
                  name="balance"
                  label={t('balance')}
                  labelCol={{span: 6}}
                  labelAlign="left"
                  rules={[
                    {
                      required: pageType?.type !== 'DETAIL',
                      message: t('required'),
                    },
                    {
                      whitespace: true,
                      message: t('required'),
                    },
                  ]}>
                  <CustomInput disabled={pageType?.disabled} type="money" />
                </Form.Item>
              )}
              <Form.Item
                name="allowedIps"
                label={t('allowedIps')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                  {
                    pattern: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    message: t('ipAddressFormat'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {pageType?.type !== 'ADD' && (
                <Form.Item
                  name="secret"
                  label={t('secret')}
                  labelCol={{span: 6}}
                  labelAlign="left"
                  rules={[
                    {
                      required: pageType?.type !== 'DETAIL',
                      message: t('required'),
                    },
                    {
                      whitespace: true,
                      message: t('required'),
                    },
                  ]}>
                  <Row>
                    <Col span={19}>
                      <CustomInput
                        type="text"
                        disabled={true}
                        value={detailChannel?.secret}
                      />
                    </Col>
                    <Col span={4}>
                      <Popconfirm
                        title={t('generateConfirm')}
                        okText={t('yes')}
                        onConfirm={() => regenerate(detailChannel?.channel_id)}
                        cancelText={t('no')}>
                        <Button
                          type="light"
                          size="medium"
                          text="Re - Generate"
                          loading={loadingGenerate}
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </Form.Item>
              )}
              <Form.Item
                name="settlementType"
                label={t('settlementType')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type === 'ADD',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.type !== 'ADD'}
                  name="settlementType"
                  type="select"
                  source={[
                    {label: 'Settlement', value: 'settlement'},
                    {label: 'Deposit', value: 'deposit'},
                  ]}
                  onChange={(e) => form.setFieldsValue({settlementType: e})}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.settlementType !== currentValues.settlementType
                }>
                {({getFieldValue}) => {
                  return getFieldValue('settlementType') === 'settlement' ? (
                    <Form.Item
                      name="limit"
                      label={t('limit')}
                      labelCol={{span: 6}}
                      labelAlign="left"
                      rules={[
                        {
                          required: pageType?.type === 'ADD',
                          message: t('required'),
                        },
                      ]}>
                      <CustomInput
                        type="money"
                        disabled={pageType?.type !== 'ADD'}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item
                name="description"
                label={t('description')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input.TextArea
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChannelFormPage;
