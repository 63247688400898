import {providerTypes} from '../../action-types';
import Services from '../../../../services';
import {message} from 'antd';
import {isEmptyObject} from '../../../../utilities';

const {
  SET_PROVIDER_LIST,
  SET_PROVIDER_DETAIL,
  SET_LIST_BANK,
  SET_PROVIDER_LIST_TOTAL,
} = providerTypes;

const setProviderList = (payload) => ({
  type: SET_PROVIDER_LIST,
  payload,
});
const setListBank = (payload) => ({
  type: SET_LIST_BANK,
  payload,
});
const setProviderTotal = (payload) => ({
  type: SET_PROVIDER_LIST_TOTAL,
  payload,
});

const getListProvider = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`provider`, {...request, size: 10000})
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values?.data);
          dispatch(setProviderList(res.data.values.data));
          dispatch(setProviderTotal(res.data.values.totalData));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const getListBank = (showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('bank')
      .then((res) => {
        if (res.data.values) {
          const result = res.data.values.data.map((item) => ({
            value: item.bank_name,
            label: item.bank_name,
          }));
          resolve(result);
          dispatch(setListBank(result));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createProvider = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('provider', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteProvider = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`provider/${request.provider_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateProvider = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`provider/${request.provider_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailProvider = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (!isEmptyObject(data)) {
      const result = {
        provider_id: data.provider_id,
        email: data.email,
        name: data.name,
        userId: data.user_id,
        description: data.description,
        contractFile: data.contract_file,
      };
      resolve(result);
      dispatch({
        type: SET_PROVIDER_DETAIL,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_PROVIDER_DETAIL,
        payload: {},
      });
    }
  });
};

const getFile = (fileName, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`file/${fileName}`, null, 'blob')
      .then(async (res) => {
        resolve(res);
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl);
      })
      .catch((err) => {
        reject(err);
        message.error(err?.response?.data?.message || err.message);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const getDetailProvider = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`provider/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailProvider(data));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

export {
  getListProvider,
  createProvider,
  updateProvider,
  deleteProvider,
  setDetailProvider,
  getListBank,
  getFile,
  getDetailProvider,
};
