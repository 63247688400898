import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Checkbox, Col, Form, Input, Row} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Cookies from 'universal-cookie';
import {BackgroundDarkBlue, IkiBillGreyLogo} from '../../assets';
import {Button} from '../../components';
import {login} from '../../redux/store/actions/authentication';
import {
  dismissLoadingButton,
  showLoadingButton,
} from '../../redux/store/actions/loading';
import {colors, history} from '../../utilities';

const cookies = new Cookies();

const LoginPage = () => {
  const dispatch = useDispatch();
  const username = cookies.get('username');
  const pathHistory = cookies.get('history');
  const {t} = useTranslation('loginPage');
  const onFinish = (values) => {
    if (values.remember) {
      cookies.set('username', values.username);
    }
    dispatch(login(values, showLoadingButton, dismissLoadingButton)).then(
      (res) => {
        if (pathHistory !== '/') {
          history.replace(pathHistory);
        } else {
          history.replace('/dashboard');
        }
        cookies.set('history', '/')
      },
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <div
            style={{
              backgroundImage: `url(${BackgroundDarkBlue})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '100vh',
              margin: 0,
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div
              style={{
                backgroundColor: colors.white,
                borderRadius: 10,
                alignSelf: 'center',
                padding: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
              <img src={IkiBillGreyLogo} alt="logo" width="118" />
              <h2 style={{margin: '10px 0'}}>{t('title')}</h2>
              <Form
                name="login"
                initialValues={{username, remember: username ? true : false}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t('required'),
                    },
                  ]}>
                  <Input
                    placeholder="username"
                    style={{borderRadius: 20}}
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('required'),
                    },
                  ]}>
                  <Input.Password
                    placeholder="password"
                    style={{borderRadius: 20}}
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>{t('rememberMe')}</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    text="Submit"
                    rounded
                    type="primary"
                    block
                    htmlType="submit"
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
