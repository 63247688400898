import { dashboardTypes } from '../../action-types';

const { SET_IFRAME_URL, SET_BALANCE, SET_WITHDRAWAL_FEE } = dashboardTypes;

const initialState = {
  iframeUrl: '',
  balance: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IFRAME_URL:
      return { ...state, iframeUrl: payload };
    case SET_BALANCE:
      return { ...state, balance: payload };
    case SET_WITHDRAWAL_FEE:
      return { ...state, withdrawalFee: payload };
    default:
      return state;
  }
};

export default reducer;
