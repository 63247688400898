import {Col, Input, message, Row, Form} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Button,
  Container,
  CustomForm,
  CustomInput,
  PageTitle,
} from '../../components';
import {
  billMasterActions,
  clientActions,
  loadingActions,
  penaltyActions,
} from '../../redux/store/actions';
import {dismissLoading, showLoading} from '../../redux/store/actions/loading';
import {getDetailPenalty} from '../../redux/store/actions/penalty';
import {history} from '../../utilities';

const {setDetailPenalty, createPenalty, updatePenalty} = penaltyActions;
const {getListBillMaster} = billMasterActions;
const {getListClient} = clientActions;
const {showLoadingButton, dismissLoadingButton} = loadingActions;

const PenaltyFormPage = () => {
  const dispatch = useDispatch();
  const {detailPenalty} = useSelector((state) => state.penalty);
  const {listBillMaster} = useSelector((state) => state.billMaster);
  const {listClient} = useSelector((state) => state.client);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingBillMaster, setLoadingBillMaster] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [listBillMasterFiltered, setListBillMasterFiltered] = useState([
    listBillMaster,
  ]);
  const {t} = useTranslation('penaltyFormPage');
  useEffect(() => {
    if (location?.state) {
      if (location.state?.type !== 'ADD') {
        const request = {id: location.state.id};
        dispatch(getDetailPenalty(request, showLoading, dismissLoading));
      }
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-penalty',
          });
          break;
        case 'EDIT':
          setPageType({
            title: t('editTitle'),
            type: 'EDIT',
            form: 'edit-penalty',
            disabled: true,
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-penalty',
          });
          break;
      }
    } else {
      history.goBack();
    }
    getBillMaster();
    getClient();
    return () => {
      dispatch(setDetailPenalty({}));
    };
  }, [location.state, t]);
  const getBillMaster = () => {
    setLoadingBillMaster(true);
    dispatch(getListBillMaster())
      .then((res) => {
        setListBillMasterFiltered(billMasterListMapper(res));
      })
      .finally(() => {
        setLoadingBillMaster(false);
      });
  };
  const getClient = () => {
    setLoadingClient(true);
    dispatch(getListClient()).finally(() => {
      setLoadingClient(false);
    });
  };
  const onFinish = (values) => {
    if (pageType?.type === 'ADD') {
      dispatch(
        createPenalty(values, showLoadingButton, dismissLoadingButton),
      ).then(() => {
        message.success(t('success'));
        history.push('/penalty');
      });
    } else {
      dispatch(
        updatePenalty({...values, penalty_id: detailPenalty.penalty_id}),
        showLoadingButton,
        dismissLoadingButton,
      ).then(() => {
        message.success(t('editSuccess'));
        history.push('/penalty');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const billMasterListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.bill_master_id,
      label: item.name,
    }));
    return result;
  };
  const clientListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.client_id,
      label: item.name,
    }));
    return result;
  };
  const onSelectClient = (e, name) => {
    const client = listClient.filter((item) => item.client_id === e)[0];
    setListBillMasterFiltered(
      billMasterListMapper(
        listBillMaster.filter(
          (item) => item.provider_id === client.provider_id,
        ),
      ),
    );
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm
              name={pageType?.form}
              defaultValues={detailPenalty}
              onSubmit={onFinish}
              onSubmitFailed={onFinishFailed}>
              <Form.Item
                name="clientId"
                label={t('clientName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'EDIT',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="clientId"
                  type="select"
                  source={clientListMapper(listClient)}
                  onChange={onSelectClient}
                  loading={loadingClient}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.clientId !== currentValues.clientId
                }>
                {({getFieldValue}) => {
                  return (
                    <Form.Item
                      name="billMasterId"
                      label={t('billName')}
                      labelCol={{span: 6}}
                      labelAlign="left"
                      rules={[
                        {
                          required: pageType?.type !== 'DETAIL',
                          message: t('required'),
                        },
                        {
                          whitespace: true,
                          message: t('required'),
                        },
                      ]}>
                      <CustomInput
                        disabled={
                          pageType?.disabled || !getFieldValue('clientId')
                        }
                        name="billMasterId"
                        type="select"
                        source={listBillMasterFiltered}
                        loading={loadingBillMaster}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="amount"
                label={t('amount')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                  () => ({
                    validator(_, value) {
                      if (value <= 0) {
                        return Promise.reject(t('minAmount'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <CustomInput type="money" />
              </Form.Item>
              <Form.Item
                name="remarks"
                label={t('remarks')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'EDIT',
                    message: t('required'),
                  },
                  {
                    type: 'string',
                    max: 50,
                    message: t('maxLength', {length: 50}),
                  },
                ]}>
                <Input.TextArea
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PenaltyFormPage;
