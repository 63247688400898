import {providerTypes} from '../../action-types';

const {
  SET_PROVIDER_LIST,
  SET_PROVIDER_DETAIL,
  SET_LIST_BANK,
  SET_PROVIDER_LIST_TOTAL,
} = providerTypes;

const initialState = {
  listProvider: [],
  detailProvider: {},
  listBank: [],
  totalProvider: '',
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_PROVIDER_LIST:
      return {...state, listProvider: payload};
    case SET_PROVIDER_DETAIL:
      return {...state, detailProvider: payload};
    case SET_LIST_BANK:
      return {...state, listBank: payload};
    case SET_PROVIDER_LIST_TOTAL:
      return {...state, totalProvider: payload};
    default:
      return state;
  }
};

export default reducer;
