import {PayCircleOutlined} from '@ant-design/icons';
import {Col, Form, Input, message, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  BillPaymentModal,
  Button,
  Container,
  CustomForm,
  CustomInput,
  CustomTable,
  PageTitle,
} from '../../components';
import {
  billActions,
  clientActions,
  loadingActions,
  providerActions,
} from '../../redux/store/actions';
import {
  history,
  phoneNumberNormalize,
  thousandSeparator,
} from '../../utilities';

const {
  createClient,
  setDetailClient,
  updateClient,
  getDetailClient,
} = clientActions;
const {getListBill, payBill} = billActions;
const {getListProvider} = providerActions;
const {
  showLoadingButton,
  dismissLoadingButton,
  dismissLoading,
  showLoading,
} = loadingActions;

const ClientFormPage = () => {
  const dispatch = useDispatch();
  const {detailClient} = useSelector((state) => state.client);
  const {listBill} = useSelector((state) => state.bill);
  const {listProvider} = useSelector((state) => state.provider);
  const {authData} = useSelector((state) => state.auth);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [detailPayment, setDetailPayment] = useState({});
  const [form] = Form.useForm();
  const {t} = useTranslation('clientFormPage');
  useEffect(() => {
    if (location?.state) {
      if (location.state?.type !== 'ADD') {
        const request = {id: location?.state?.id};
        dispatch(getDetailClient(request, showLoading, null)).then((res) => {
          dispatch(
            getListBill({clientId: location?.state?.id}, null, dismissLoading),
          );
        });
      }
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-client',
          });
          break;
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-client',
            disabled: true,
          });
          break;
        case 'EDIT':
          setPageType({
            title: t('editTitle'),
            type: 'EDIT',
            form: 'edit-client',
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    } else {
      history.push('/client');
    }
    getProvider();
    return () => {
      dispatch(setDetailClient({}));
    };
  }, []);
  const getProvider = () => {
    setLoadingProvider(true);
    dispatch(getListProvider()).finally(() => {
      setLoadingProvider(false);
    });
  };
  const createColumns = () => {
    return [
      {
        title: t('billMasterName'),
        dataIndex: 'bill_master_name',
        key: 'bill_master_name',
        sorter: (a, b) => a.bill_master_name - b.bill_master_name,
      },
      {
        title: t('billAmount'),
        dataIndex: 'bill_amount',
        key: 'bill_amount',
        align: 'right',
        sorter: (a, b) => a.bill_amount - b.bill_amount,
        render: (item) => thousandSeparator(item, 'Rp '),
      },
      {
        title: t('billDate'),
        dataIndex: 'bill_date',
        key: 'bill_date',
        sorter: (a, b) => a.bill_date - b.bill_date,
      },
      {
        title: t('billDueDate'),
        dataIndex: 'bill_due_date',
        key: 'bill_due_date',
        sorter: (a, b) => a.bill_due_date - b.bill_due_date,
      },
      {
        title: t('status'),
        dataIndex: 'is_paid',
        key: 'is_paid',
        sorter: (a, b) => a.is_paid - b.is_paid,
        render: (item) =>
          item ? (
            <p className="text-success">{t('paid')}</p>
          ) : (
            <p className="text-danger">{t('unpaid')}</p>
          ),
      },
      // {
      //   title: t('action'),
      //   dataIndex: 'action',
      //   key: 'action',
      //   width: '15%',
      //   render: (index, item) => {
      //     return (
      //       <Row>
      //         {!item.is_paid && authData.roles === 'provider' && (
      //           <Col xs={24} md={24} lg={24} xl={8}>
      //             <Button
      //               icon={<PayCircleOutlined />}
      //               text={t('payBill')}
      //               type="light"
      //               rounded
      //               size="small"
      //               onClick={() => {
      //                 setVisibleModal(true);
      //                 setDetailPayment(item);
      //               }}
      //             />
      //           </Col>
      //         )}
      //       </Row>
      //     );
      //   },
      // },
    ];
  };
  const onFinish = (values) => {
    const request = {
      customerId: values.customerId,
      providerId: values.providerId,
      data: {
        name: values.name,
        mobile: `0${values.mobile}`,
        address: values.address,
      },
    };
    if (pageType.type === 'ADD') {
      dispatch(
        createClient(request, showLoadingButton, dismissLoadingButton),
      ).then(() => {
        message.success(t('success'));
        history.push('/client');
      });
    } else {
      dispatch(
        updateClient(
          {
            client_id: detailClient.client_id,
            ...request,
          },
          showLoadingButton,
          dismissLoadingButton,
        ),
      ).then(() => {
        message.success(t('editSuccess'));
        history.push('/client');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const providerListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.provider_id,
      label: item.name,
    }));
    return result;
  };
  const onClickPay = (values) => {
    const request = {
      bill_id: detailPayment.bill_id,
      remarks: values.remarks,
    };
    dispatch(payBill(request, showLoading, null))
      .then(() => {
        dispatch(
          getListBill(
            {clientId: detailPayment.client_id},
            null,
            dismissLoading,
          ),
        ).then(() => {
          setVisibleModal(false);
        });
      })
      .finally(() => {
        dispatch(dismissLoading());
      });
  };
  return (
    <div>
      <BillPaymentModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        initialValues={detailPayment}
        onFinish={onClickPay}
        onFinishFailed={onFinishFailed}
        t={t}
      />
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm
              name={pageType?.form}
              formRef={form}
              defaultValues={detailClient}
              onSubmit={onFinish}
              onSubmitFailed={onFinishFailed}>
              <Form.Item
                name="providerId"
                label={t('provider')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type === 'ADD',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.type !== 'ADD'}
                  name="providerId"
                  type="select"
                  source={providerListMapper(listProvider)}
                  loading={loadingProvider}
                />
              </Form.Item>
              <Form.Item
                name="customerId"
                label={t('customerId')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type === 'ADD',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.type !== 'ADD'}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label={t('name')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="mobile"
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.mobile !== currentValues.mobile
                }
                label={t('mobile')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                  {
                    transform: (value) => Number(value),
                    type: 'number',
                    message: t('numberValidation'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                  addonBefore={'+62'}
                  onChange={(e) => {
                    form.setFieldsValue({
                      mobile: phoneNumberNormalize(e.target.value),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="address"
                label={t('address')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input.TextArea
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              {pageType.type === 'DETAIL' && listBill.length ? (
                <Form.Item
                  name="bill"
                  label={t('bill')}
                  labelCol={{span: 24}}
                  labelAlign="left">
                  <CustomTable
                    filterBy={[
                      'name',
                      'bill_type',
                      'bill_date',
                      'bill_due_date',
                    ]}
                    createColumns={createColumns}
                    dataSource={listBill}
                  />
                </Form.Item>
              ) : null}
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientFormPage;
