import {DeleteFilled, EditFilled, EyeOutlined} from '@ant-design/icons';
import {Col, message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {
  clientBillMasterActions,
  loadingActions,
} from '../../redux/store/actions';
import {compare, history, thousandSeparator} from '../../utilities';

const {
  showLoading,
  showLoadingTable,
  dismissLoadingTable,
  dismissLoading,
} = loadingActions;
const {
  getListClientBillMaster,
  deleteClientBillMaster,
  setDetailClientBillMaster,
} = clientBillMasterActions;

const ClientBillMasterPage = () => {
  const dispatch = useDispatch();
  const {listClientBillMaster} = useSelector((state) => state.clientBillMaster);
  const {authData} = useSelector((state) => state.auth);
  const {t} = useTranslation('clientBillMasterPage');
  useEffect(() => {
    getList();
  }, []);
  const getList = (req) => {
    dispatch(
      getListClientBillMaster(req, showLoadingTable, dismissLoadingTable),
    );
  };
  const onClickDelete = (req) => {
    dispatch(deleteClientBillMaster(req, showLoading, dismissLoading))
      .then((res) => {
        message.success(
          t('deleteSuccess', {
            clientBillMasterId: `${req.bill_master_name} - ${req.client_name}`,
          }),
        );
        getList();
      })
      .catch((err) => {
        message.error(
          t('deleteFailed', {clientBillMasterId: req.client_bill_master_id}),
        );
      });
  };
  const showButton = authData.roles === 'provider';
  const createColumns = () => {
    return [
      {
        title: t('billMasterId'),
        dataIndex: 'bill_master_name',
        key: 'bill_master_name',
        sorter: (a, b) => compare(a, b, 'bill_master_name'),
      },
      {
        title: t('clientId'),
        dataIndex: 'client_name',
        key: 'client_name',
        sorter: (a, b) => compare(a, b, 'client_name'),
      },
      {
        title: t('billAmount'),
        dataIndex: 'bill_amount',
        key: 'bill_amount',
        align: 'right',
        render: (item) => thousandSeparator(item, 'Rp '),
        sorter: (a, b) => a.bill_amount - b.bill_amount,
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => compare(a, b, 'description'),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '35%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    dispatch(
                      setDetailClientBillMaster(
                        item.client_bill_master_id,
                        listClientBillMaster,
                      ),
                    ).then(() => {
                      history.push({
                        pathname: '/bill-management/clientbillmaster/detail',
                        state: {type: 'DETAIL', key: 'CLIENTBILLMASTER'},
                      });
                    });
                  }}
                />
              </Col>
              {showButton && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Button
                    icon={<EditFilled />}
                    text={t('buttonEdit')}
                    type="light"
                    rounded
                    size="small"
                    onClick={() => {
                      dispatch(
                        setDetailClientBillMaster(
                          item.client_bill_master_id,
                          listClientBillMaster,
                        ),
                      ).then(() => {
                        history.push({
                          pathname: '/bill-management/clientbillmaster/edit',
                          state: {type: 'EDIT', key: 'CLIENTBILLMASTER'},
                        });
                      });
                    }}
                  />
                </Col>
              )}
              {showButton && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Popconfirm
                    title={t('deleteConfirm', {
                      clientBillMasterId: `${item.bill_master_name} - ${item.client_name}`,
                    })}
                    okText={t('yes')}
                    onConfirm={() => onClickDelete(item)}
                    cancelText={t('no')}>
                    <Button
                      icon={<DeleteFilled />}
                      text={t('buttonDelete')}
                      type="light"
                      rounded
                      size="small"
                    />
                  </Popconfirm>
                </Col>
              )}
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd={showButton}
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/bill-management/clientbillmaster/add',
          params: {type: 'ADD', key: 'CLIENTBILLMASTER'},
        }}
      />
      <Container>
        <CustomTable
          uploadPageParams={{key: 'CLIENTBILLMASTER'}}
          showButtonUpload={showButton}
          uploadPage="/bill-management/clientbillmaster/upload"
          placeholderSearch={t('placeholderSearch')}
          filterBy={['bill_amount', 'bill_master_name', 'client_name', 'description']}
          createColumns={createColumns}
          dataSource={listClientBillMaster}
        />
      </Container>
    </div>
  );
};

export default ClientBillMasterPage;
