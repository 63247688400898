import {Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Container, PageTitle} from '../../components';
import {getIframeUrl} from '../../redux/store/actions/dashboard';
import {dismissLoading, showLoading} from '../../redux/store/actions/loading';

const DashboardPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIframeUrl(null, showLoading, dismissLoading));
  }, []);
  const {iframeUrl} = useSelector((state) => state.dashboard);
  const {t} = useTranslation('dashboardPage');
  return (
    <>
      <PageTitle
        title={t('title')}
        showButtonAdd={false}
        showBackButton={false}
      />
      <Container>
        <Row style={{height: '100vh'}}>
          <iframe
            title="Dashboard"
            style={{border: 'none'}}
            src={iframeUrl}
            height="100%"
            width="100%"></iframe>
        </Row>
      </Container>
    </>
  );
};

export default DashboardPage;
