import {channelJournalTypes} from '../../action-types';

const {SET_CHANNEL_JOURNAL_LIST, SET_CHANNEL_JOURNAL_DETAIL, SET_CHANNEL_JOURNAL_LIST_TOTAL} = channelJournalTypes;


const initialState = {
  listChannelJournal: [],
  totalChannelJournal: '',
  detailChannelJournal: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_CHANNEL_JOURNAL_LIST:
      return {...state, listChannelJournal: payload};
    case SET_CHANNEL_JOURNAL_DETAIL:
      return {...state, detailChannelJournal: payload};
    case SET_CHANNEL_JOURNAL_LIST_TOTAL:
      return {...state, totalChannelJournal: payload};
    default:
      return state;
  }
};

export default reducer;
