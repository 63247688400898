import {
  AccountBookOutlined,
  BankOutlined,
  BarChartOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  DeploymentUnitOutlined,
  DollarCircleOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  InteractionOutlined,
  NodeIndexOutlined,
  ProfileOutlined,
  TeamOutlined,
  TransactionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {EnglishFlag, IndonesianFlag} from '../../assets';

const USER = ['DASHBOARD', 'JOURNAL'];

const PROVIDER = [
  'DASHBOARD',
  'PROVIDER',
  'CLIENT',
  'BILLMASTER',
  'CLIENTBILLMASTER',
  'BILL',
  'PENALTY',
  'JOURNAL',
  'UPLOAD',
];

const SUPER_ADMIN = [
  'DASHBOARD',
  'USERS',
  'PROVIDER',
  'CLIENT',
  'BILLMASTER',
  'CLIENTBILLMASTER',
  'BILL',
  'PENALTY',
  'JOURNAL',
  'UPLOAD',
  'CHANNEL',
  'CHANNELJOURNAL',
  'CHANNELXPROVIDER',
];

const SIDER_MENU_LIST = [
  {
    menu: 'Dashboard',
    key: 'DASHBOARD',
    path: '/dashboard',
    role: 'all',
    icon: <BarChartOutlined />,
  },
  {
    menu: 'User',
    key: 'USERS',
    path: '/user',
    role: 'super_admin',
    icon: <UserOutlined />,
  },
  {
    menu: 'Provider',
    key: 'PROVIDER',
    path: '/provider',
    role: 'all',
    icon: <BankOutlined />,
  },
  {
    menu: 'Client',
    key: 'CLIENT',
    path: '/client',
    role: 'all',
    icon: <TeamOutlined />,
  },
  {
    menu: 'Bill Management',
    key: 'BILL',
    role: 'all',
    icon: <ProfileOutlined />,
    child: [
      {
        menu: 'Bill Master',
        key: 'BILLMASTER',
        path: '/bill-management/billmaster',
        role: 'all',
        icon: <FileProtectOutlined />,
      },
      {
        menu: 'Client Bill Master',
        key: 'CLIENTBILLMASTER',
        path: '/bill-management/clientbillmaster',
        role: 'all',
        icon: <FileDoneOutlined />,
      },
      {
        menu: 'Bill',
        key: 'BILL',
        path: '/bill-management/bill',
        role: 'all',
        icon: <AccountBookOutlined />,
      },
    ],
  },
  {
    menu: 'Channel Management',
    key: 'CHANNEL',
    role: 'super_admin',
    icon: <ClusterOutlined />,
    child: [
      {
        menu: 'Channel',
        key: 'CHANNEL',
        path: '/channel-management/channel',
        role: 'super_admin',
        icon: <DeploymentUnitOutlined />,
      },
      {
        menu: 'Channel Journal',
        key: 'CHANNELJOURNAL',
        path: '/channel-management/channel-journal',
        role: 'super_admin',
        icon: <InteractionOutlined />,
      },
      {
        menu: 'Channel to Provider',
        key: 'CHANNELXPROVIDER',
        path: '/channel-management/channel-to-provider',
        role: 'super_admin',
        icon: <NodeIndexOutlined />,
      },
    ],
  },
  {
    menu: 'Penalty',
    key: 'PENALTY',
    path: '/penalty',
    role: 'all',
    icon: <DollarCircleOutlined />,
  },
  {
    menu: 'Transaction Journal',
    key: 'JOURNAL',
    path: '/journal',
    role: 'all',
    icon: <TransactionOutlined />,
  },
  {
    menu: 'Upload',
    key: 'UPLOAD',
    path: '/upload',
    role: 'all',
    icon: <CloudUploadOutlined />,
  },
];

const CLIENT_FILE_TEMPLATE = [
  {label: 'EXCEL', value: '/files/client_template.xlsx'},
  // {label: 'CSV', value: '/files/client_template.csv'},
  // {label: 'TXT', value: '/files/client_template.txt'},
];
const CLIENT_BILL_MASTER_FILE_TEMPLATE = [
  {label: 'EXCEL', value: '/files/client_bill_master_template.xlsx'},
  // {label: 'CSV', value: '/files/client_bill_master_template.csv'},
  // {label: 'TXT', value: '/files/client_bill_master_template.txt'},
];
const PENALTY_TEMPLATE = [
  {label: 'EXCEL', value: '/files/penalty_template.xlsx'},
  // {label: 'CSV', value: '/files/penalty_template.csv'},
  // {label: 'TXT', value: '/files/penalty_template.txt'},
];
const LANGUAGE = [
  {label: 'Indonesia', value: 'id', icon: IndonesianFlag},
  {label: 'English', value: 'en', icon: EnglishFlag},
];

export {
  SIDER_MENU_LIST,
  CLIENT_FILE_TEMPLATE,
  CLIENT_BILL_MASTER_FILE_TEMPLATE,
  PENALTY_TEMPLATE,
  LANGUAGE,
  USER,
  SUPER_ADMIN,
  PROVIDER,
};
