import {EyeOutlined, PayCircleOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  BillPaymentModal,
  Button,
  Container,
  CustomTable,
  PageTitle,
} from '../../components';
import {
  billActions,
  clientActions,
  loadingActions,
} from '../../redux/store/actions';
import {compare, history, thousandSeparator} from '../../utilities';

const {
  showLoadingTable,
  dismissLoadingTable,
  showLoading,
  dismissLoading,
} = loadingActions;
const {getListBill, payBill} = billActions;
const {getListClient} = clientActions;

const BillPage = () => {
  const dispatch = useDispatch();
  const {listBill} = useSelector((state) => state.bill);
  const {listClient} = useSelector((state) => state.client);
  const {authData} = useSelector((state) => state.auth);
  const {filterValue} = useSelector((state) => state.table);
  const [visibleModal, setVisibleModal] = useState(false);
  const [detailPayment, setDetailPayment] = useState({});
  const {t} = useTranslation('billPage');
  useEffect(() => {
    getList(filterValue);
    dispatch(getListClient());
  }, []);
  const getList = (req, filter) => {
    let request = {};
    if (filter) {
      request = {
        ...filter,
        ...req,
      };
    } else {
      request = {
        ...req,
      };
    }
    dispatch(getListBill(request, showLoadingTable, dismissLoadingTable));
  };
  const clientListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.client_id,
      label: item.name || item.client_id,
    }));
    return result;
  };
  const createColumns = () => {
    return [
      {
        title: t('clientName'),
        dataIndex: 'client_name',
        key: 'client_name',
        sorter: (a, b) => compare(a, b, 'client_name'),
      },
      {
        title: t('billMasterName'),
        dataIndex: 'bill_master_name',
        key: 'bill_master_name',
        sorter: (a, b) => compare(a, b, 'bill_master_name'),
      },
      {
        title: t('providerName'),
        dataIndex: 'provider_name',
        key: 'provider_name',
        sorter: (a, b) => compare(a, b, 'provider_name'),
      },
      {
        title: t('billAmount'),
        dataIndex: 'bill_amount',
        key: 'bill_amount',
        align: 'right',
        sorter: (a, b) => a.bill_amount - b.bill_amount,
        render: (item) => thousandSeparator(item, 'Rp '),
      },
      {
        title: t('billDate'),
        dataIndex: 'bill_date',
        key: 'bill_date',
        sorter: (a, b) => new Date(a.bill_date) - new Date(b.bill_date),
      },
      {
        title: t('billDueDate'),
        dataIndex: 'bill_due_date',
        key: 'bill_due_date',
        sorter: (a, b) => new Date(a.bill_due_date) - new Date(b.bill_due_date),
      },
      {
        title: t('status'),
        dataIndex: 'is_paid',
        key: 'is_paid',
        filters: [
          {text: t('paid'), value: true},
          {text: t('unpaid'), value: false},
        ],
        onFilter: (value, record) => record.is_paid === value,
        render: (item) =>
          item ? (
            <p className="text-success">{t('paid')}</p>
          ) : (
            <p className="text-danger">{t('unpaid')}</p>
          ),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '10%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/bill-management/bill/detail',
                      state: {type: 'DETAIL', key: 'BILL', id: item.bill_id},
                    });
                  }}
                />
              </Col>
              {/* {!item.is_paid && authData.roles === 'provider' && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Button
                    icon={<PayCircleOutlined />}
                    text={t('payBill')}
                    type="light"
                    rounded
                    size="small"
                    onClick={() => {
                      setVisibleModal(true);
                      setDetailPayment(item);
                    }}
                  />
                </Col>
              )} */}
            </Row>
          );
        },
      },
    ];
  };
  const filterField = [
    {
      label: t('clientName'),
      fieldName: 'clientId',
      type: 'select',
      validation: [
        {
          required: true,
          message: 'field required',
        },
      ],
      dataSource: clientListMapper(listClient),
    },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onClickPay = (values) => {
    const request = {
      bill_id: detailPayment.bill_id,
      remarks: values.remarks,
    };
    dispatch(payBill(request, showLoading, null))
      .then(() => {
        dispatch(getListBill(null, null, dismissLoading)).then(() => {
          setVisibleModal(false);
        });
      })
      .finally(() => {
        dispatch(dismissLoading());
      });
  };
  return (
    <div>
      <BillPaymentModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        initialValues={detailPayment}
        onFinish={onClickPay}
        onFinishFailed={onFinishFailed}
        t={t}
      />
      <PageTitle title={t('title')} />
      <Container>
        <CustomTable
          onFilter={getList}
          resetFilter={getList}
          filterField={filterField}
          createColumns={createColumns}
          dataSource={listBill}
          placeholderSearch={t('placeholderSearch')}
          filterBy={[
            'provider_name',
            'bill_master_name',
            'client_name',
            'bill_date',
            'bill_due_date',
            'bill_amount',
          ]}
        />
      </Container>
    </div>
  );
};

export default BillPage;
