const SET_PROVIDER_LIST = '@provider/set-provider-list';
const SET_PROVIDER_DETAIL = '@provider/set-provider-detail';
const SET_PROVIDER_LIST_TOTAL = '@provider/set-provider-list-total';
const SET_LIST_BANK = '@provider/set-list-bank';

export {
  SET_PROVIDER_LIST,
  SET_PROVIDER_DETAIL,
  SET_LIST_BANK,
  SET_PROVIDER_LIST_TOTAL,
};
