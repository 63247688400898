export const colors = {
  primary: '#0275d8',
  secondary: '#5cb85c',
  info: '#5bc0de',
  warning: '#f0ad4e',
  danger: '#d9534f',
  dark: '#292b2c',
  light: '#f7f7f7',
  black: '#000',
  white: '#fff',
  disabled: '#cfcfcf',
  transparent: 'rgba(255,255,255,0.1)',
  grey1: '#707070',
  grey2: '#cfcfcf',
  grey3: '#F2F2F2',
  grey4: '#AEAFAF',
  grey5: '#BBBBBB',
  grey6: '#E6E6E6',
  orange1: '#C61B1B',
  orange2: '#FE912A',
  red1: '#DD2C2C',
  red2: '#ED6675',
  blue1: '#15C6FB',
  blue2: '#318EBE',
  blue3: '#0e3546',
  blue4: '#4D627D',
  blue5: '#1711C5',
  green1: '#56CF5B',
  green2: '#8EF276',
  green3: '#008200',
  yellow1: '#F2C121',
  a: '#15C6FB',
  b: '#318EBE',
  c: '#4D627D',
  d: '#1711C5',
  e: '#0e3546',
  f: '#FF66FF',
  g: '#FF33FF',
  h: '#FF3399',
  i: '#CC0066',
  j: '#ED6675',
  k: '#DD2C2C',
  l: '#C61B1B',
  m: '#FE912A',
  n: '#F2C121',
  o: '#8EF276',
  p: '#56CF5B',
  q: '#4CA44F',
  r: '#367038',
  S: '#333300',
  t: '#4D4D00',
  u: '#8D8D02',
  v: '#C3C302',
  w: '#CC6600',
  x: '#994C00',
  y: '#663300',
  z: '#331900',
};
