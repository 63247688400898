const SET_TRANSACTION_JOURNAL_LIST =
  '@transaction-journal/set-transaction-journal-list';
const SET_TRANSACTION_JOURNAL_DETAIL =
  '@transaction-journal/set-transaction-journal-detail';
const SET_TRANSACTION_JOURNAL_LIST_TOTAL =
  '@transaction-journal/set-transaction-journal-list-total';

export {
  SET_TRANSACTION_JOURNAL_LIST,
  SET_TRANSACTION_JOURNAL_DETAIL,
  SET_TRANSACTION_JOURNAL_LIST_TOTAL,
};
