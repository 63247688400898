import {DeleteFilled, EditFilled, EyeOutlined} from '@ant-design/icons';
import {Col, message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {clientActions, loadingActions} from '../../redux/store/actions';
import {getListProvider} from '../../redux/store/actions/provider';
import {resetTableState} from '../../redux/store/actions/table';
import {compare, history} from '../../utilities';

const {showLoadingTable, dismissLoadingTable} = loadingActions;
const {getListClient, deleteClient} = clientActions;

const ClientPage = () => {
  const dispatch = useDispatch();
  const {listClient} = useSelector((state) => state.client);
  const {listProvider} = useSelector((state) => state.provider);
  const {filterValue} = useSelector((state) => state.table);
  const {authData} = useSelector((state) => state.auth);
  const {t} = useTranslation('clientPage');
  useEffect(() => {
    getList(filterValue);
    dispatch(getListProvider());
  }, []);
  const getList = (req, filter) => {
    dispatch(getListClient(req, showLoadingTable, dismissLoadingTable));
  };
  const onClickDelete = (req) => {
    dispatch(deleteClient(req, showLoadingTable, dismissLoadingTable))
      .then((res) => {
        message.success(t('deleteSuccess', {customerId: req.customer_id}));
        dispatch(getListClient(null, null, dismissLoadingTable));
      })
      .catch((err) => {
        message.error(t('deleteFailed', {name: req.name}));
      });
  };
  const providerListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.provider_id,
      label: item.name,
    }));
    return result;
  };
  const filterField = [
    {
      label: t('providerName'),
      fieldName: 'providerId',
      type: 'select',
      validation: [
        {
          required: true,
          message: 'field required',
        },
      ],
      dataSource: providerListMapper(listProvider),
    },
  ];
  const showButton = authData.roles === 'provider';
  const createColumns = () => {
    return [
      {
        title: t('customerId'),
        dataIndex: 'customer_id',
        key: 'customer_id',
        sorter: (a, b) => compare(a, b, 'customer_id'),
      },
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => compare(a, b, 'name'),
        render: (item) => item || '-',
      },
      {
        title: t('providerName'),
        dataIndex: 'provider_name',
        key: 'provider_name',
        sorter: (a, b) => compare(a, b, 'provider_name'),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '35%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    dispatch(resetTableState());
                    history.push({
                      pathname: '/client/detail',
                      state: {
                        type: 'DETAIL',
                        key: 'CLIENT',
                        id: item.client_id,
                      },
                    });
                  }}
                />
              </Col>
              {showButton && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Button
                    icon={<EditFilled />}
                    text={t('buttonEdit')}
                    type="light"
                    rounded
                    size="small"
                    onClick={() => {
                      history.push({
                        pathname: '/client/edit',
                        state: {
                          type: 'EDIT',
                          key: 'CLIENT',
                          id: item.client_id,
                        },
                      });
                    }}
                  />
                </Col>
              )}
              {showButton && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Popconfirm
                    title={t('deleteConfirm', {customerId: item.customer_id})}
                    okText={t('yes')}
                    onConfirm={() => onClickDelete(item)}
                    cancelText={t('no')}>
                    <Button
                      icon={<DeleteFilled />}
                      text={t('buttonDelete')}
                      type="light"
                      rounded
                      size="small"
                    />
                  </Popconfirm>
                </Col>
              )}
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd={authData.roles === 'provider'}
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/client/add',
          params: {type: 'ADD', key: 'CLIENT'},
        }}
      />
      <Container>
        <CustomTable
          onFilter={getList}
          resetFilter={getList}
          filterField={filterField}
          uploadPageParams={{key: 'CLIENT'}}
          showButtonUpload={showButton}
          uploadPage={'/client/upload'}
          placeholderSearch={t('placeholderSearch')}
          filterBy={['name', 'customer_id', 'provider_name']}
          createColumns={createColumns}
          dataSource={listClient}
        />
      </Container>
    </div>
  );
};

export default ClientPage;
