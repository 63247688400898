import {
  CaretDownOutlined,
  CloudDownloadOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {Col, Form, message, Row, Space, Upload} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomInput, PageTitle} from '../../components';
import {getListBillMaster} from '../../redux/store/actions/billmaster';
import {getListProvider} from '../../redux/store/actions/provider';
import Services from '../../services';
import {
  CLIENT_BILL_MASTER_FILE_TEMPLATE,
  colors,
  history,
} from '../../utilities';

const ClientBillMasterUploadPage = () => {
  const dispatch = useDispatch();
  const {listBillMaster} = useSelector((state) => state.billMaster);
  const {listProvider} = useSelector((state) => state.provider);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBillMaster, setLoadingBillMaster] = useState(false);
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [form] = Form.useForm();
  const {t} = useTranslation('clientBillMasterUploadPage');
  useEffect(() => {
    getBillMaster();
    getProvider();
  }, []);
  const onFinish = (values) => {
    if (!fileList.length) {
      form.setFields([
        {
          name: 'file',
          errors: ['Field Required'],
        },
      ]);
    } else {
      setLoading(true);
      form.setFields([
        {
          name: 'file',
          errors: false,
        },
      ]);
      const formData = new FormData();
      formData.append('master', values.file.file);
      formData.append('type', 'client_bill_master');
      formData.append(
        'params',
        JSON.stringify({
          provider_id: values.provider_id,
          bill_master_id: values.bill_master_id,
        }),
      );
      Services.Post('upload', formData, true)
        .then(() => {
          message.success('File Successfully Uploaded');
          history.push('/bill-management/clientbillmaster');
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message || err?.response?.data || err?.message,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const getProvider = () => {
    setLoadingProvider(true);
    dispatch(getListProvider()).finally(() => {
      setLoadingProvider(false);
    });
  };
  const providerListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.provider_id,
      label: item.name,
    }));
    return result;
  };
  const getBillMaster = (request) => {
    return new Promise((resolve, reject) => {
      setLoadingBillMaster(true);
      dispatch(getListBillMaster(request))
        .then((res) => {
          resolve(res);
        })
        .finally(() => {
          setLoadingBillMaster(false);
        });
    });
  };
  const billMasterListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.bill_master_id,
      label: item.name,
    }));
    return result;
  };
  const props = {
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    listType: 'picture',
    fileList,
    onRemove: () => {
      setFileList([]);
    },
  };
  const onSelect = (value) => {
    getBillMaster({providerId: value}).then(() => {
      form.setFieldsValue({bill_master_id: ''});
    });
  };
  return (
    <div>
      <PageTitle title={t('title')} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <Form
              form={form}
              name="upload-clientbillmaster"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                name="provider_id"
                label={t('providerName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Field Required',
                  },
                ]}>
                <CustomInput
                  name="provider_id"
                  type="select"
                  onChange={onSelect}
                  source={providerListMapper(listProvider)}
                  loading={loadingProvider}
                />
              </Form.Item>
              <Form.Item
                name="bill_master_id"
                label={t('billName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Field Required',
                  },
                ]}>
                <CustomInput
                  name="bill_master_id"
                  type="select"
                  source={billMasterListMapper(listBillMaster)}
                  loading={loadingBillMaster}
                />
              </Form.Item>
              <p
                style={{
                  color: colors.grey1,
                  fontSize: 11,
                  textAlign: 'right',
                }}>
                {t('notes', {size: '10 MB', type: '.xls'})}
              </p>
              <Form.Item
                name="file"
                label={t('file')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Field Required',
                  },
                ]}>
                <Upload.Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{t('uploadDescription')}</p>
                </Upload.Dragger>
              </Form.Item>
              <Row justify="end">
                <Col>
                  <Button
                    isButtonDropdown
                    menuDropdown={CLIENT_BILL_MASTER_FILE_TEMPLATE}
                    text={
                      <Space>
                        <CloudDownloadOutlined />
                        {t('download')} <CaretDownOutlined />{' '}
                      </Space>
                    }
                    block
                    type="light"
                  />
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item>
                    <Button
                      text={t('upload')}
                      block
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientBillMasterUploadPage;
