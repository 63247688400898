import {Col, Form, Input, message, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Button, Container, CustomInput, PageTitle} from '../../components';
import {
  billMasterActions,
  clientActions,
  clientBillMasterActions,
  loadingActions,
} from '../../redux/store/actions';
import {history} from '../../utilities';

const {
  createClientBillMaster,
  setDetailClientBillMaster,
  updateClientBillMaster,
} = clientBillMasterActions;
const {getListClient} = clientActions;
const {getListBillMaster} = billMasterActions;
const {showLoadingButton, dismissLoadingButton} = loadingActions;

const ClientBillMasterFormPage = () => {
  const dispatch = useDispatch();
  const {listClient} = useSelector((state) => state.client);
  const {listBillMaster} = useSelector((state) => state.billMaster);
  const {detailClientBillMaster} = useSelector(
    (state) => state.clientBillMaster,
  );
  const [form] = Form.useForm();
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingBillMaster, setLoadingBillMaster] = useState(false);
  const [listBillMasterFiltered, setListBillMasterFiltered] = useState([]);
  const {t} = useTranslation('clientBillMasterFormPage');
  useEffect(() => {
    if (location?.state) {
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-client-bill-master',
          });
          break;
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-client-bill-master',
            disabled: true,
          });
          break;
        case 'EDIT':
          setPageType({
            title: t('editTitle'),
            type: 'EDIT',
            form: 'edit-client-bill-master',
            disabled: true,
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    }
    getClient();
    getBillMaster();
    return () => {
      dispatch(setDetailClientBillMaster());
    };
  }, [location.state, t]);
  const getClient = () => {
    setLoadingClient(true);
    dispatch(getListClient()).finally(() => {
      setLoadingClient(false);
    });
  };
  const getBillMaster = () => {
    setLoadingBillMaster(true);
    dispatch(getListBillMaster()).finally(() => {
      setLoadingBillMaster(false);
    });
  };
  const onFinish = (values) => {
    const request = {
      ...values,
    };
    if (pageType.type === 'ADD') {
      dispatch(
        createClientBillMaster(
          request,
          showLoadingButton,
          dismissLoadingButton,
        ),
      ).then(() => {
        message.success(t('success'));
        history.push('/bill-management/clientbillmaster');
      });
    } else {
      dispatch(
        updateClientBillMaster(
          {
            client_bill_master_id: detailClientBillMaster.client_bill_master_id,
            ...request,
          },
          showLoadingButton,
          dismissLoadingButton,
        ),
      ).then(() => {
        message.success(t('editSuccess'));
        history.push('/bill-management/clientbillmaster');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const clientListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.client_id,
      label: item.name,
    }));
    return result;
  };
  const billMasterListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.bill_master_id,
      label: item.name,
    }));
    return result;
  };
  const onSelectClient = (e, name) => {
    const client = listClient.filter((item) => item.client_id === e)[0];
    setListBillMasterFiltered(
      billMasterListMapper(
        listBillMaster.filter(
          (item) => item.provider_id === client.provider_id,
        ),
      ),
    );
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <Form
              form={form}
              name={pageType?.form}
              initialValues={detailClientBillMaster}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                name="clientId"
                label={t('clientId')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="clientId"
                  type="select"
                  source={clientListMapper(listClient)}
                  onChange={(e, name) => onSelectClient(e, name)}
                  loading={loadingClient}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.clientId !== currentValues.clientId
                }>
                {({getFieldValue}) => {
                  return (
                    <Form.Item
                      name="billMasterId"
                      label={t('billMasterId')}
                      labelCol={{span: 6}}
                      labelAlign="left"
                      rules={[
                        {
                          required: pageType?.type !== 'DETAIL',
                          message: t('required'),
                        },
                        {
                          whitespace: true,
                          message: t('required'),
                        },
                      ]}>
                      <CustomInput
                        disabled={
                          pageType?.disabled || !getFieldValue('clientId')
                        }
                        name="billMasterId"
                        type="select"
                        source={listBillMasterFiltered}
                        loading={loadingBillMaster}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="billAmount"
                label={t('billAmount')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  type="money"
                  disabled={pageType?.type === 'DETAIL'}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={t('description')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input.TextArea
                  style={{borderRadius: 5}}
                  disabled={pageType?.type === 'DETAIL'}
                />
              </Form.Item>
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientBillMasterFormPage;
