import {
  DeleteFilled,
  EditFilled,
  EyeOutlined,
  FormOutlined,
} from '@ant-design/icons';
import {Col, message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {billMasterActions, loadingActions} from '../../redux/store/actions';
import {colors, history, compare} from '../../utilities';

const {
  showLoading,
  dismissLoading,
  showLoadingTable,
  dismissLoadingTable,
} = loadingActions;
const {getListBillMaster, deleteBillMaster} = billMasterActions;

const BillMasterPage = () => {
  const dispatch = useDispatch();
  const {listBillMaster, totalBillMaster} = useSelector(
    (state) => state.billMaster,
  );
  const {filterValue} = useSelector((state) => state.table);
  const {authData} = useSelector((state) => state.auth);
  const {t} = useTranslation('billMasterPage');
  useEffect(() => {
    getList(filterValue);
  }, []);
  const onClickDelete = (req) => {
    dispatch(deleteBillMaster(req, showLoading, null))
      .then((res) => {
        message.success(t('deleteSuccess', {name: req.name}));
        dispatch(getListBillMaster(null, null, dismissLoading));
      })
      .catch((err) => {
        message.error(t('deleteFailed', {name: req.name}));
      });
  };
  const getList = (req, filter) => {
    let request = {};
    if (filter) {
      request = {
        ...filter,
        ...req,
      };
    } else {
      request = {
        ...req,
      };
    }
    dispatch(
      getListBillMaster(request, showLoadingTable, dismissLoadingTable, true),
    );
  };
  const createColumns = () => {
    return [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        sorter: (a, b) => compare(a, b, 'name'),
      },
      {
        title: t('billType'),
        dataIndex: 'bill_type',
        key: 'bill_type',
        width: '15%',
        sorter: (a, b) => compare(a, b, 'bill_type'),
        filters: [
          {text: t('monthly'), value: 'monthly'},
          {text: t('one_time'), value: 'one_time'},
        ],
        onFilter: (value, record) => record.bill_type === value,
      },
      {
        title: t('providerName'),
        dataIndex: 'provider_name',
        key: 'provider_name',
        width: '15%',
        sorter: (a, b) => compare(a, b, 'provider_name'),
      },
      {
        title: t('status'),
        dataIndex: 'is_approved',
        key: 'is_approved',
        width: '20%',
        render: (item) =>
          item ? (
            <div
              style={{
                padding: '5px 10px',
                borderRadius: 5,
                backgroundColor: colors.secondary,
                alignSelf: 'center',
                color: colors.white,
                fontWeight: 600,
                display: 'inline-block',
                fontSize: 12,
              }}>
              Approved
            </div>
          ) : (
            <div
              style={{
                padding: '5px 10px',
                borderRadius: 5,
                backgroundColor: colors.danger,
                alignSelf: 'center',
                color: colors.white,
                fontWeight: 600,
                display: 'inline-block',
                fontSize: 12,
              }}>
              Waiting for Approval
            </div>
          ),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '35%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/bill-management/billmaster/detail',
                      state: {
                        type: 'DETAIL',
                        key: 'BILLMASTER',
                        id: item.bill_master_id,
                      },
                    });
                  }}
                />
              </Col>
              {authData.roles === 'super_admin' && !item.is_approved ? (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Button
                    icon={<FormOutlined />}
                    text={t('buttonApproval')}
                    type="light"
                    rounded
                    size="small"
                    onClick={() => {
                      history.push({
                        pathname: '/bill-management/billmaster/approval',
                        state: {
                          type: 'APPROVAL',
                          key: 'BILLMASTER',
                          id: item.bill_master_id,
                        },
                      });
                    }}
                  />
                </Col>
              ) : (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Button
                    icon={<EditFilled />}
                    text={t('buttonEdit')}
                    type="light"
                    rounded
                    size="small"
                    onClick={() => {
                      history.push({
                        pathname: '/bill-management/billmaster/edit',
                        state: {
                          type: 'EDIT',
                          key: 'BILLMASTER',
                          id: item.bill_master_id,
                        },
                      });
                    }}
                  />
                </Col>
              )}
              {authData.roles !== 'super_admin' && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Popconfirm
                    title={t('deleteConfirm', {name: item.name})}
                    okText={t('yes')}
                    onConfirm={() => onClickDelete(item)}
                    cancelText={t('no')}>
                    <Button
                      icon={<DeleteFilled />}
                      text={t('buttonDelete')}
                      type="light"
                      rounded
                      size="small"
                    />
                  </Popconfirm>
                </Col>
              )}
            </Row>
          );
        },
      },
    ];
  };
  const filterField = [
    {
      label: t('status'),
      fieldName: 'isApproved',
      type: 'select',
      validation: [
        {
          required: true,
          message: 'field required',
        },
      ],
      dataSource: [
        {
          label: 'Approved',
          value: 'true',
        },
        {
          label: 'Waiting For Approval',
          value: 'false',
        },
      ],
    },
  ];
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd={authData.roles !== 'super_admin'}
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/bill-management/billmaster/add',
          params: {type: 'ADD', key: 'BILLMASTER'},
        }}
      />
      <Container>
        <CustomTable
          onFilter={getList}
          resetFilter={getList}
          filterField={filterField}
          placeholderSearch={t('placeholderSearch')}
          filterBy={['name', 'bill_type', 'provider_name']}
          createColumns={createColumns}
          dataSource={listBillMaster}
        />
      </Container>
    </div>
  );
};

export default BillMasterPage;
