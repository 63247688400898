import {Col, DatePicker, Form} from 'antd';
import {CustomInput} from '../../molecules';

const columnsMapper = (columns) => {
  const result = columns.map((item) => {
    if (item.sort) {
      return {
        title: item.label,
        key: item.field,
        dataIndex: item.field,
        width: item.width,
        sorter: (a, b) => a[item.field].length - b[item.field].length,
      };
    }
    return {
      title: item.label,
      key: item.field,
      dataIndex: item.field,
      width: item.width,
      sorter: (a, b) => a[item.field].length - b[item.field].length,
    };
  });
  return result;
};

const renderFieldFilter = (list) => {
  return list?.map((item) => {
    if (item.type === 'date-range') {
      return (
        <Col
          span={8}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            padding: 10,
          }}>
          <Form.Item
            name={item.fieldName}
            label={item.label}
            labelCol={{span: 6}}
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[item.fieldName] !== currentValues[item.fieldName]
            }
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'field required',
              },
            ]}>
            <DatePicker.RangePicker
              style={{borderRadius: 5}}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      );
    } else if (item.type === 'select') {
      return (
        <Col span={8}>
          <Form.Item
            name={item.fieldName}
            label={item.label}
            labelCol={{span: 10}}
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[item.fieldName] !== currentValues[item.fieldName]
            }
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'field required',
              },
            ]}>
            <CustomInput
              name={item.fieldName}
              type="select"
              source={item.dataSource}
            />
          </Form.Item>
        </Col>
      );
    }
  });
};

export {columnsMapper, renderFieldFilter};
