import {Col, Form, message, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Button, Container, CustomInput, PageTitle} from '../../components';
import {
  channelActions,
  channelToProviderActions,
  loadingActions,
  providerActions,
} from '../../redux/store/actions';
import {history} from '../../utilities';

const {showLoadingButton, dismissLoadingButton} = loadingActions;
const {createChannelToProvider} = channelToProviderActions;
const {getListProvider} = providerActions;
const {getListChannel} = channelActions;

const ChannelToProviderFormPage = () => {
  const dispatch = useDispatch();
  const {detailChannelToProvider} = useSelector(
    (state) => state.channelToProvider,
  );
  const {listProvider} = useSelector((state) => state.provider);
  const {listChannel} = useSelector((state) => state.channel);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const {t} = useTranslation('channelToProviderFormPage');
  useEffect(() => {
    if (location?.state) {
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-channelToProvider',
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    }
    getProvider();
    getChannel();
  }, [location.state, t]);
  const getProvider = () => {
    setLoadingProvider(true);
    dispatch(getListProvider()).finally(() => {
      setLoadingProvider(false);
    });
  };
  const getChannel = () => {
    setLoadingChannel(true);
    dispatch(getListChannel()).finally(() => {
      setLoadingChannel(false);
    });
  };
  const onFinish = (values) => {
    dispatch(
      createChannelToProvider(values, showLoadingButton, dismissLoadingButton),
    ).then(() => {
      message.success(t('success'));
      history.push('/channel-management/channel-to-provider');
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const providerListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.provider_id,
      label: item.name,
    }));
    return result;
  };
  const channelListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.channel_id,
      label: item.name,
    }));
    return result;
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <Form
              name={pageType?.form}
              initialValues={detailChannelToProvider}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                name="channelId"
                label={t('channelName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="channelId"
                  type="select"
                  source={channelListMapper(listChannel)}
                  loading={loadingChannel}
                />
              </Form.Item>
              <Form.Item
                name="providerId"
                label={t('providerName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="providerId"
                  type="select"
                  source={providerListMapper(listProvider)}
                  loading={loadingProvider}
                />
              </Form.Item>
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChannelToProviderFormPage;
