import {clientBillMasterTypes} from '../../action-types';
import Services from '../../../../services';
import {message} from 'antd';

const {
  SET_CLIENTBILLMASTER_LIST,
  SET_CLIENTBILLMASTER_DETAIL,
} = clientBillMasterTypes;

const setClientBillMasterList = (payload) => ({
  type: SET_CLIENTBILLMASTER_LIST,
  payload,
});

const getListClientBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`client_bill_master`, {...request, size: 10000})
      .then((res) => {
        if (res.data.values) {
          const result = res.data.values.data.map((item) => ({
            ...item,
            client_name: item?.client_data?.name,
          }));
          resolve(result);
          dispatch(setClientBillMasterList(result));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createClientBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('client_bill_master', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteClientBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`client_bill_master/${request.client_bill_master_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateClientBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`client_bill_master/${request.client_bill_master_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailClientBillMaster = (id, list, showLoading, dismissLoading) => (
  dispatch,
) => {
  return new Promise((resolve, reject) => {
    if (id && list) {
      let result = list.filter((item) => item.client_bill_master_id === id)[0];
      result = {
        client_bill_master_id: result.client_bill_master_id,
        billMasterId: result.bill_master_name,
        clientId: result.client_id,
        billAmount: result.bill_amount,
      };
      if (result) {
        resolve(result);
        dispatch({
          type: SET_CLIENTBILLMASTER_DETAIL,
          payload: result,
        });
      } else {
        reject(result);
      }
    } else {
      dispatch({
        type: SET_CLIENTBILLMASTER_DETAIL,
        payload: {},
      });
    }
  });
};
export {
  getListClientBillMaster,
  createClientBillMaster,
  updateClientBillMaster,
  deleteClientBillMaster,
  setDetailClientBillMaster,
};
