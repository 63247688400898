import {channelTypes} from '../../action-types';
import Services from '../../../../services';
import {message} from 'antd';
import {isEmptyObject} from '../../../../utilities';

const {SET_CHANNEL_LIST, SET_CHANNEL_DETAIL} = channelTypes;

const setChannelList = (payload) => ({
  type: SET_CHANNEL_LIST,
  payload,
});

const getListChannel = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('channel', {size: 10000})
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values?.data);
          dispatch(setChannelList(res.data.values?.data));
        } else {
          dispatch(setChannelList([]));
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        dispatch(setChannelList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createChannel = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('channel', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteChannel = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`channel/${request.channel_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateChannel = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`channel/${request.channel_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailChannel = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (!isEmptyObject(data)) {
      const result = {
        limit: data.limit,
        balance: data.balance,
        settlementType: data.settlement_type,
        channel_id: data.channel_id,
        identifier: data.identifier,
        description: data.description,
        name: data.name,
        allowedIps: data.allowed_ips,
        secret: '*************',
      };
      resolve(result);
      dispatch({
        type: SET_CHANNEL_DETAIL,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_CHANNEL_DETAIL,
        payload: {},
      });
    }
  });
};

const getDetailChannel = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`channel/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailChannel(data, request.role));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const regenerateSecretKey = (id, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`channel/secret/${id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const depositChannel = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('deposit', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};
export {
  getListChannel,
  createChannel,
  updateChannel,
  deleteChannel,
  setDetailChannel,
  regenerateSecretKey,
  depositChannel,
  getDetailChannel,
};
