import {authActionTypes} from '../../action-types';

const {SET_AUTH_DATA} = authActionTypes;

const initialState = {
  authData: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_AUTH_DATA:
      return {...state, authData: payload};
    default:
      return state;
  }
};

export default reducer;
