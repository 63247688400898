import {FrownOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Button} from '../../components';
import {colors, history} from '../../utilities';

const PageNotFound = () => {
  let location = useLocation();
  const {t} = useTranslation('pageNotFound');
  return (
    <div>
      <Row justify="center">
        <Col span={8} style={{textAlign: 'center'}}>
          <div
            style={{
              backgroundColor: colors.white,
              borderRadius: 10,
              padding: 50,
              boxShadow: '-10px 10px 10px -10px rgba(0,0,0,0.25)',
            }}>
            <FrownOutlined style={{fontSize: 50}} />
            <p style={{fontSize: '4em', marginBottom: 5}}>{t('title')}</p>
            <p style={{fontSize: '1em'}}>{t('description')}</p>
            <Row>
              <Col span={11}>
                <Button
                  text="Back"
                  type="light"
                  block
                  onClick={() =>
                    location.state?.noPermission
                      ? history.go(-2)
                      : history.goBack()
                  }
                />
              </Col>
              <Col span={11} offset={1}>
                <Button
                  text="Dashboard"
                  type="secondary"
                  block
                  onClick={() => history.push('/dashboard')}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageNotFound;
