import {clientTypes} from '../../action-types';
import Services from '../../../../services';
import {message} from 'antd';
import {getListProvider} from '../provider';
import {isEmptyObject, removeFirstZero} from '../../../../utilities';

const {SET_CLIENT_LIST, SET_CLIENT_DETAIL, SET_CLIENT_LIST_TOTAL} = clientTypes;

const setClientList = (payload) => ({
  type: SET_CLIENT_LIST,
  payload,
});
const setClientTotal = (payload) => ({
  type: SET_CLIENT_LIST_TOTAL,
  payload,
});

const getListClient = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`client`, {...request, size: 10000})
      .then((res) => {
        if (res.data.values) {
          let result = [];
          const {data, totalData} = res.data.values;
          result = data.map((item) => {
            Object.keys(item.data).forEach((x) => {
              Object.assign(item, {[x]: item.data[x]});
            });
            return item;
          });
          resolve(result);
          dispatch(setClientList(result));
          dispatch(setClientTotal(totalData));
        } else {
          dispatch(setClientList([]));
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        dispatch(setClientList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createClient = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('client', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteClient = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`client/${request.client_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateClient = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`client/${request.client_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response.data.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailClient = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (!isEmptyObject(data)) {
      Object.keys(data.data).forEach((x) => {
        Object.assign(data, {[x]: data.data[x]});
      });
      const result = {
        client_id: data.client_id,
        providerId: data.provider_id,
        customerId: data.customer_id,
        name: data.name,
        mobile: removeFirstZero(data.mobile),
        address: data.address,
      };
      resolve(result);
      dispatch({
        type: SET_CLIENT_DETAIL,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_CLIENT_DETAIL,
        payload: {},
      });
    }
  });
};
const getDetailClient = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`client/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailClient(data));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};
export {
  getListClient,
  createClient,
  updateClient,
  deleteClient,
  setDetailClient,
  getDetailClient,
};
