import {clientTypes} from '../../action-types';

const {SET_CLIENT_LIST, SET_CLIENT_DETAIL, SET_CLIENT_LIST_TOTAL} = clientTypes;

const initialState = {
  listClient: [],
  detailClient: {},
  totalClient: '',
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_CLIENT_LIST:
      return {...state, listClient: payload};
    case SET_CLIENT_DETAIL:
      return {...state, detailClient: payload};
    case SET_CLIENT_LIST_TOTAL:
      return {...state, totalClient: payload};
    default:
      return state;
  }
};

export default reducer;
