import {message} from 'antd';
import Services from '../../../../services';
import {channelJournalTypes} from '../../action-types';
import {getListChannel} from '../channel';

const {
  SET_CHANNEL_JOURNAL_LIST,
  SET_CHANNEL_JOURNAL_DETAIL,
  SET_CHANNEL_JOURNAL_LIST_TOTAL,
} = channelJournalTypes;

const setChannelJournalList = (payload) => ({
  type: SET_CHANNEL_JOURNAL_LIST,
  payload,
});
const setChannelJournalTotal = (payload) => ({
  type: SET_CHANNEL_JOURNAL_LIST_TOTAL,
  payload,
});

const getListChannelJournal = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`channel_journal`, request)
      .then((res) => {
        if (res.data.values) {
          dispatch(getListChannel())
            .then((resChannel) => {
              const result = res.data.values.data.map((item) => ({
                ...item,
                channel: resChannel.filter(
                  (x) => x.channel_id === item.channel_id,
                )[0].name,
              }));
              resolve(result);
              dispatch(setChannelJournalList(result));
              dispatch(setChannelJournalTotal(res.data.values.totalData));
            })
            .catch(() => {
              resolve(res.data.values.data);
              dispatch(setChannelJournalTotal(res.data.values.totalData));
              dispatch(setChannelJournalList(res.data.values.data));
            });
        } else {
          dispatch(setChannelJournalList([]));
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        dispatch(setChannelJournalList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailChannelJournal = (id, list, showLoading, dismissLoading) => (
  dispatch,
) => {
  return new Promise((resolve, reject) => {
    if (id && list) {
      let result = list.filter((item) => item.channel_journal_id === id)[0];
      if (result) {
        resolve(result);
        dispatch({
          type: SET_CHANNEL_JOURNAL_DETAIL,
          payload: result,
        });
      } else {
        reject(result);
      }
    } else {
      dispatch({
        type: SET_CHANNEL_JOURNAL_DETAIL,
        payload: {},
      });
    }
  });
};
export {getListChannelJournal, setDetailChannelJournal};
