import {layoutActionTypes} from '../../action-types';

const {SET_SELECTED_MENU} = layoutActionTypes;

const initialState = {
  openMenuKey: [],
  selectedMenuKey: [],
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_SELECTED_MENU:
      return {...state, openMenuKey: [payload.openKeys], selectedMenuKey: [payload.selectedKeys]};
    default:
      return state;
  }
};

export default reducer;