import {tableTypes} from '../../action-types';

const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER_VALUE,
  SET_FILTERED,
  SET_SEARCH_VALUE,
} = tableTypes;

const initialState = {
  page: 1,
  pageSize: 10,
  filterValue: null,
  filtered: false,
  searchValue: '',
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_PAGE:
      return {...state, page: payload};
    case SET_PAGE_SIZE:
      return {...state, pageSize: payload};
    case SET_FILTER_VALUE:
      return {...state, filterValue: payload};
    case SET_FILTERED:
      return {...state, filtered: payload};
    case SET_SEARCH_VALUE:
      return {...state, searchValue: payload};
    default:
      return state;
  }
};

export default reducer;
