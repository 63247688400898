import {billTypes} from '../../action-types';

const {SET_BILL_LIST, SET_BILL_DETAIL, SET_BILL_LIST_TOTAL} = billTypes;

const initialState = {
  listBill: [],
  totalBill: '',
  detailBill: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_BILL_LIST:
      return {...state, listBill: payload};
    case SET_BILL_DETAIL:
      return {...state, detailBill: payload};
    case SET_BILL_LIST_TOTAL:
      return {...state, totalBill: payload};
    default:
      return state;
  }
};

export default reducer;
