import {channelToProviderTypes} from '../../action-types';

const {
  SET_CHANNEL_TO_PROVIDER_LIST,
  SET_CHANNEL_TO_PROVIDER_DETAIL,
} = channelToProviderTypes;

const initialState = {
  listChannelToProvider: [],
  detailChannelToProvider: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_CHANNEL_TO_PROVIDER_LIST:
      return {...state, listChannelToProvider: payload};
    case SET_CHANNEL_TO_PROVIDER_DETAIL:
      return {...state, detailChannelToProvider: payload};
    default:
      return state;
  }
};

export default reducer;
