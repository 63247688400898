import {clientBillMasterTypes} from '../../action-types';

const {
  SET_CLIENTBILLMASTER_LIST,
  SET_CLIENTBILLMASTER_DETAIL,
  SET_CLIENTBILLMASTER_LIST_TOTAL,
} = clientBillMasterTypes;

const initialState = {
  listClientBillMaster: [],
  totalClientBillMaster: '',
  detailClientBillMaster: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_CLIENTBILLMASTER_LIST:
      return {...state, listClientBillMaster: payload};
    case SET_CLIENTBILLMASTER_DETAIL:
      return {...state, detailClientBillMaster: payload};
    case SET_CLIENTBILLMASTER_LIST_TOTAL:
      return {...state, totalClientBillMaster: payload};
    default:
      return state;
  }
};

export default reducer;
