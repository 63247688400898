import {message} from 'antd';
import Moment from 'moment';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Container, CustomTable, PageTitle} from '../../components';
import {
  loadingActions,
  transactionJournalActions,
} from '../../redux/store/actions';
import {compare, thousandSeparator} from '../../utilities';

const {showLoadingTable, dismissLoadingTable} = loadingActions;
const {getListTransactionJournal} = transactionJournalActions;

const TransactionJournalPage = () => {
  const dispatch = useDispatch();
  const {listTransactionJournal, totalTransactionJournal} = useSelector(
    (state) => state.transactionJournal,
  );
  const {page, pageSize, filterValue} = useSelector((state) => state.table);
  const {t} = useTranslation('transactionJournalPage');
  useEffect(() => {
    getList({page, size: pageSize}, filterValue);
  }, [dispatch]);
  const getList = (req, filter) => {
    let request = {};
    if (!req && !filter) {
      request = {
        startDate: Moment().subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: Moment(new Date()).format('YYYY-MM-DD'),
      };
    } else if (req.dateRange) {
      request = {
        startDate: Moment(req.dateRange[0]).format('YYYY-MM-DD'),
        endDate: Moment(req.dateRange[1]).format('YYYY-MM-DD'),
      };
    } else if (filter) {
      request = {
        startDate: Moment(filter.dateRange[0]).format('YYYY-MM-DD'),
        endDate: Moment(filter.dateRange[1]).format('YYYY-MM-DD'),
        ...req,
      };
    } else {
      request = {
        startDate: Moment().subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: Moment(new Date()).format('YYYY-MM-DD'),
        ...req,
      };
    }
    dispatch(
      getListTransactionJournal(request, showLoadingTable, dismissLoadingTable),
    ).then(() => {
      if (!filter?.dateRange && !req?.dateRange) {
        message.info(t('displayDefault'));
      }
    });
  };
  const createColumns = () => {
    return [
      {
        title: t('type'),
        dataIndex: 'type',
        key: 'type',
        width: '15%',
        render: (item) => {
          switch (item) {
            case 'debit':
              return <p className="text-success">{t('debit')}</p>;
            case 'credit':
              return <p className="text-danger">{t('credit')}</p>;
            default:
              return item;
          }
        },
        sorter: (a, b) => compare(a, b, 'type'),
      },
      {
        title: t('datetime'),
        dataIndex: 'datetime',
        key: 'datetime',
        width: '20%',
        render: (item) =>
          Moment(item).utc(false).format('DD MMM YYYY HH:mm:ss'),
        sorter: (a, b) => Moment(a.datetime).unix() - Moment(b.datetime).unix(),
      },
      {
        title: t('amount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        width: '15%',
        render: (item, allItem) => thousandSeparator(item, 'Rp '),
        sorter: (a, b) => a.amount - b.amount,
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        key: 'remarks',
        width: '30%',
        sorter: (a, b) => compare(a, b, 'remarks'),
      },
      {
        title: t('reference'),
        dataIndex: 'reference',
        key: 'reference',
        width: '20%',
        sorter: (a, b) => a.reference - b.reference,
      },
    ];
  };
  const filterField = [
    {
      label: t('date'),
      fieldName: 'dateRange',
      type: 'date-range',
      validation: [
        {
          required: true,
          message: 'field required',
        },
      ],
    },
  ];
  return (
    <div>
      <PageTitle title={t('title')} />
      <Container>
        <CustomTable
          onFilter={getList}
          onPageChange={getList}
          onSizeChange={getList}
          resetFilter={getList}
          filterField={filterField}
          initialFilterValues={
            !filterValue && {
              dateRange: [Moment().subtract(7, 'd'), Moment()],
            }
          }
          createColumns={createColumns}
          dataSource={listTransactionJournal}
          totalData={totalTransactionJournal}
        />
      </Container>
    </div>
  );
};

export default TransactionJournalPage;
