import {billMasterTypes} from '../../action-types';
import Services from '../../../../services';
import Moment from 'moment';
import {message} from 'antd';
import {isEmptyObject} from '../../../../utilities';

const {
  SET_BILLMASTER_LIST,
  SET_BILLMASTER_DETAIL,
  SET_BILLMASTER_LIST_TOTAL,
} = billMasterTypes;

const setBillMasterList = (payload) => ({
  type: SET_BILLMASTER_LIST,
  payload,
});

const setBillMasterTotal = (payload) => ({
  type: SET_BILLMASTER_LIST_TOTAL,
  payload,
});

const getListBillMaster = (
  request,
  showLoading,
  dismissLoading,
  approvalFilter,
) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`bill_master`, {...request, size: 10000})
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values.data);
          dispatch(setBillMasterList(res.data.values.data));
          dispatch(setBillMasterTotal(res.data.values.totalData));
        } else {
          dispatch(setBillMasterList([]));
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        dispatch(setBillMasterList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('bill_master', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`bill_master/${request.bill_master_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`bill_master/${request.bill_master_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateBillMasterByAdmin = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`bill_master/admin/${request.bill_master_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const approveBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`bill_master/approval/${request.bill_master_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailBillMaster = (data, role) => (dispatch) => {
  return new Promise((resolve, reject) => {


    if (!isEmptyObject(data)) {
      if (!data.admin_shares.length) {
        data.admin_shares = [''];
      }
      const result = {
        bill_master_id: data.bill_master_id,
        providerId: data.provider_id,
        name: data.name,
        billType: data.bill_type,
        billDate: Moment(data.bill_date),
        billDueDate: Moment(data.bill_due_date),
        penaltyType: data.penalty_type,
        penaltyAmount: data.penalty_amount,
        adminType: data.admin_type,
        adminAmount: data.admin_amount,
        adminShares: role === 'super_admin' && data.admin_shares,
        minimumBillAdminAmount: data.min_bill_admin_amount
      };

      if (result) {
        resolve(result);
        dispatch({
          type: SET_BILLMASTER_DETAIL,
          payload: result,
        });
      } else {
        reject(result);
      }
    } else {
      dispatch({
        type: SET_BILLMASTER_DETAIL,
        payload: {
          billDate: Moment(),
          billDueDate: Moment(),
        },
      });
    }
  });
};
const getDetailBillMaster = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`bill_master/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailBillMaster(data, request.role));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};
export {
  getListBillMaster,
  createBillMaster,
  updateBillMaster,
  deleteBillMaster,
  setDetailBillMaster,
  updateBillMasterByAdmin,
  approveBillMaster,
  getDetailBillMaster,
};
