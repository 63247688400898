import {DeleteFilled, EditFilled, EyeOutlined} from '@ant-design/icons';
import {Col, message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {loadingActions, usersActions} from '../../redux/store/actions';
import {compare, history} from '../../utilities';

const {
  showLoading,
  dismissLoading,
  showLoadingTable,
  dismissLoadingTable,
} = loadingActions;
const {getListUsers, deleteUsers} = usersActions;

const UsersPage = () => {
  const dispatch = useDispatch();
  const {listUsers} = useSelector((state) => state.users);
  const {t} = useTranslation('usersPage');
  useEffect(() => {
    dispatch(getListUsers(null, showLoadingTable, dismissLoadingTable));
  }, []);
  const onClickDelete = (req) => {
    dispatch(deleteUsers(req, showLoading, null))
      .then((res) => {
        message.success(t('deleteSuccess', {name: req.name}));
        dispatch(getListUsers(null, null, dismissLoading));
      })
      .catch((err) => {
        dispatch(dismissLoading());
        message.error(t('deleteFailed', {name: req.name}));
      });
  };
  const createColumns = () => {
    return [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => compare(a, b, 'name'),
      },
      {
        title: t('email'),
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => compare(a, b, 'email'),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '35%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/user/detail',
                      state: {type: 'DETAIL', key: 'USERS', id: item.user_id},
                    });
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EditFilled />}
                  text={t('buttonEdit')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/user/edit',
                      state: {type: 'EDIT', key: 'USERS', id: item.user_id},
                    });
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Popconfirm
                  title={t('deleteConfirm', {name: item.name})}
                  okText={t('yes')}
                  onConfirm={() => onClickDelete(item)}
                  cancelText={t('no')}>
                  <Button
                    icon={<DeleteFilled />}
                    text={t('buttonDelete')}
                    type="light"
                    rounded
                    size="small"
                  />
                </Popconfirm>
              </Col>
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/user/add',
          params: {type: 'ADD', key: 'USERS'},
        }}
      />
      <Container>
        <CustomTable
          placeholderSearch={t('placeholderSearch')}
          filterBy={['name', 'email']}
          createColumns={createColumns}
          dataSource={listUsers}
        />
      </Container>
    </div>
  );
};

export default UsersPage;
