import {penaltyTypes} from '../../action-types';
import Services from '../../../../services';
import {getListClient} from '../client';
import {message} from 'antd';
import {isEmptyObject} from '../../../../utilities';

const {SET_PENALTY_LIST, SET_PENALTY_DETAIL} = penaltyTypes;

const setPenaltyList = (payload) => ({
  type: SET_PENALTY_LIST,
  payload,
});

const getListPenalty = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('penalty', {size: 10000})
      .then((resPenalty) => {
        if (resPenalty.data.values) {
          const listPenalty = resPenalty.data.values.data;
          dispatch(getListClient({size: 10000}))
            .then((resClient) => {
              const result = listPenalty.map((item) => ({
                ...item,
                clientName: resClient.filter(
                  (x) => x.client_id === item.client_id,
                )[0].name,
              }));
              resolve(result);
              dispatch(setPenaltyList(result));
            })
            .catch((errClient) => {
              reject(errClient);
            })
            .finally(() => {
              dismissLoading && dispatch(dismissLoading());
            });
        } else {
          reject(resPenalty);
        }
      })
      .catch((errPenalty) => {
        reject(errPenalty);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createPenalty = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('penalty', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updatePenalty = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`penalty/${request.penalty_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailPenalty = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (!isEmptyObject(data)) {
      const result = {
        penalty_id: data.penalty_id,
        clientId: data.client_id,
        billMasterId: data.bill_master_id,
        amount: data.amount,
        remarks: data.remarks,
      };
      resolve(result);
      dispatch({
        type: SET_PENALTY_DETAIL,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_PENALTY_DETAIL,
        payload: {},
      });
    }
  });
};

const getDetailPenalty = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`penalty/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailPenalty(data));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

export {
  getListPenalty,
  createPenalty,
  updatePenalty,
  setDetailPenalty,
  getDetailPenalty,
};
