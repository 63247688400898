import {channelTypes} from '../../action-types';

const {SET_CHANNEL_LIST, SET_CHANNEL_DETAIL} = channelTypes;

const initialState = {
  listChannel: [],
  detailChannel: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_CHANNEL_LIST:
      return {...state, listChannel: payload};
    case SET_CHANNEL_DETAIL:
      return {...state, detailChannel: payload};
    default:
      return state;
  }
};

export default reducer;
