import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  CustomForm,
  CustomInput,
  PageTitle,
} from '../../components';
import {
  billMasterActions,
  providerActions,
  usersActions,
} from '../../redux/store/actions';
import { dismissLoading, showLoading } from '../../redux/store/actions/loading';
import { history } from '../../utilities';

const {
  createBillMaster,
  setDetailBillMaster,
  updateBillMaster,
  updateBillMasterByAdmin,
  approveBillMaster,
  getDetailBillMaster,
} = billMasterActions;
const { getListProvider } = providerActions;
const { getListUsers } = usersActions;

const BillMasterFormPage = () => {
  const dispatch = useDispatch();
  const { detailBillMaster } = useSelector((state) => state.billMaster);
  const { listProvider } = useSelector((state) => state.provider);
  const { listUsers } = useSelector((state) => state.users);
  const { authData } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const { t } = useTranslation('billMasterFormPage');
  useEffect(() => {
    if (location?.state) {
      if (location.state?.type !== 'ADD') {
        const request = { id: location?.state?.id, role: authData.roles };
        dispatch(
          getDetailBillMaster(request, showLoading, dismissLoading),
        ).then((res) => {
          dispatch(setDetailBillMaster(res, authData.roles)).then((res) => {
            form.setFieldsValue(res);
          });
        });
      }
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-bill-master',
          });
          break;
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-bill-master',
            disabled: true,
            disabledAdminShares: true,
          });
          break;
        case 'EDIT':
          if (authData.roles === 'super_admin') {
            setPageType({
              title: t('editTitle'),
              type: 'EDIT',
              form: 'edit-bill-master',
              disabled: true,
            });
          } else if (authData.roles === 'provider') {
            setPageType({
              title: t('editTitle'),
              type: 'EDIT',
              form: 'edit-bill-master',
              disabledAdminShares: true,
            });
          }
          break;
        case 'APPROVAL':
          setPageType({
            title: t('approvalTitle'),
            type: 'APPROVAL',
            form: 'approval-bill-master',
            disabled: true,
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    } else {
      history.push('/bill-management/billmaster');
    }
    getProvider();
    if (authData.roles === 'super_admin') {
      getUsers();
    }
    return () => {
      dispatch(setDetailBillMaster({}));
    };
  }, [location.state, t]);
  const getProvider = () => {
    setLoadingProvider(true);
    dispatch(getListProvider()).finally(() => {
      setLoadingProvider(false);
    });
  };
  const getUsers = () => {
    setLoadingUsers(true);
    dispatch(getListUsers()).finally(() => {
      setLoadingUsers(false);
    });
  };
  const onFinish = (values) => {
    const request = {
      ...values,
      billDueDate: values.billDueDate.format('YYYY-MM-DD'),
      billDate: values.billDate.format('YYYY-MM-DD'),
      penaltyAmount: values.penaltyType === 'no_penalty' ? 0 : values.penaltyAmount || 0,
      adminAmount: values?.adminAmount || 0,
      minimumBillAdminAmount: values.minimumBillAdminAmount
    };
    if (pageType.type === 'ADD') {
      setLoading(true);
      dispatch(createBillMaster(request))
        .then(() => {
          message.success(t('success'));
          history.push('/bill-management/billmaster');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (pageType.type === 'APPROVAL') {
      let x = 0;
      values?.adminShares?.forEach((item) => {
        x += item.share;
      });
      if (x !== Number(values.adminAmount)) {
        form.setFields([
          {
            name: 'adminShares',
            errors: ['Admin shares amount must be equal with Admin Fee Amount'],
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'adminShares',
            errors: false,
          },
        ]);
        setLoading(true);
        dispatch(
          approveBillMaster({
            bill_master_id: detailBillMaster.bill_master_id,
            ...request,
          }),
        )
          .then(() => {
            message.success(t('approveSuccess'));
            history.push('/bill-management/billmaster');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      if (authData.roles === 'provider') {
        dispatch(
          updateBillMaster({
            bill_master_id: detailBillMaster.bill_master_id,
            ...request,
          }),
        )
          .then(() => {
            message.success(t('editSuccess'));
            history.push('/bill-management/billmaster');
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (authData.roles === 'super_admin') {
        dispatch(
          updateBillMasterByAdmin({
            bill_master_id: detailBillMaster.bill_master_id,
            ...request,
          }),
        )
          .then(() => {
            message.success(t('editSuccess'));
            history.push('/bill-management/billmaster');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const providerListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.provider_id,
      label: item.name,
    }));
    return result;
  };
  const userListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.user_id,
      label: item.name,
    }));
    return result;
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm
              formRef={form}
              name={pageType?.form}
              defaultValues={detailBillMaster}
              onSubmit={onFinish}
              onSubmitFailed={onFinishFailed}>
              {pageType?.type === 'DETAIL' &&
                <Form.Item
                  name="bill_master_id"
                  label="Bill Master ID"
                  labelCol={{ span: 6 }}
                  labelAlign="left"
                  rules={[
                    {
                      required: pageType?.type !== 'DETAIL',
                      message: t('required'),
                    },
                    {
                      whitespace: true,
                      message: t('required'),
                    },
                    {
                      type: 'string',
                      max: 50,
                      message: t('maxLength', { length: 50 }),
                    },
                  ]}>
                  <Input
                    style={{ borderRadius: 5 }}
                    disabled={pageType?.disabled}
                  />
                </Form.Item>
              }
              <Form.Item
                name="providerId"
                label={t('providerId')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.type !== 'ADD'}
                  name="providerId"
                  type="select"
                  source={providerListMapper(listProvider)}
                  loading={loadingProvider}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label={t('name')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                  {
                    type: 'string',
                    max: 50,
                    message: t('maxLength', { length: 50 }),
                  },
                ]}>
                <Input
                  style={{ borderRadius: 5 }}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="billType"
                label={t('billType')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="billType"
                  type="select"
                  source={[
                    { value: 'monthly', label: t('monthly') },
                    { value: 'one_time', label: t('one_time') },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="billDate"
                label={t('billDate')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                ]}>
                <DatePicker
                  onChange={(e) => form.validateFields(['billDueDate'])}
                  disabled={pageType?.disabled}
                  style={{ borderRadius: 5, width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="billDueDate"
                label={t('billDueDate')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        Moment(value).isBefore(
                          Moment(getFieldValue('billDate')),
                        )
                      ) {
                        return Promise.reject(t('dueDateValidation'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <DatePicker
                  onChange={(e) => form.validateFields(['billDate'])}
                  disabled={pageType?.disabled}
                  style={{ borderRadius: 5, width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="penaltyType"
                label={t('penaltyType')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="penaltyType"
                  type="select"
                  source={[
                    { value: 'fixed', label: t('fixed') },
                    { value: 'no_penalty', label: t('noPenalty') },
                  ]}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.penaltyType !== currentValues.penaltyType
                }>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="penaltyAmount"
                      label={t('penaltyAmount')}
                      labelCol={{ span: 6 }}
                      labelAlign="left"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(t('minAmount'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}>
                      <CustomInput
                        disabled={
                          pageType?.disabled ||
                          getFieldValue('penaltyType') === 'no_penalty'
                        }
                        type="money"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="adminType"
                label={t('adminType')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="adminType"
                  type="select"
                  source={[
                    { value: 'include', label: t('include') },
                    { value: 'exclude', label: t('exclude') },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="adminAmount"
                label={t('adminAmount')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(t('minAmount'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <CustomInput
                  disabled={pageType?.type !== 'ADD'}
                  type="money"
                  defaultValue={0}
                />
              </Form.Item>
              <Form.Item
                name="minimumBillAdminAmount"
                label={t('minimumBillAdminAmount')}
                labelCol={{ span: 6 }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: '213',
                  },

                ]}>
                <CustomInput
                  disabled={pageType?.type !== 'ADD'}
                  type="money"
                  defaultValue={0}
                />
              </Form.Item>

              {(!detailBillMaster?.adminShares?.length &&
                pageType?.type !== 'APPROVAL') ||
                pageType?.type === 'ADD' ||
                authData.roles !== 'super_admin' ? null : (
                <>
                  <Row>
                    <Col span={6}>
                      <label className="label-required">
                        {t('adminShares')}
                      </label>
                    </Col>
                    <Col span={8}>
                      <label>{t('name')}</label>
                    </Col>
                    <Col offset={1} span={8}>
                      <label>{t('shareAmount')}</label>
                    </Col>
                  </Row>
                  <Form.List
                    name="adminShares"
                    rules={[
                      {
                        required: pageType?.type !== 'DETAIL',
                        message: t('required'),
                      },
                    ]}>
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field) => {
                          return (
                            <Row>
                              <Col span={6} />
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  rules={[
                                    {
                                      required: pageType?.type !== 'DETAIL',
                                      message: t('required'),
                                    },
                                  ]}
                                  name={[field.name, 'user_id']}
                                  fieldKey={[field.fieldKey, 'user_id']}>
                                  <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                      option?.label
                                        ?.toLowerCase()
                                        .indexOf(input?.toLowerCase()) >= 0
                                    }
                                    className="border-normal"
                                    loading={loadingUsers}
                                    options={userListMapper(listUsers)}
                                    disabled={pageType?.disabledAdminShares}
                                  />
                                </Form.Item>
                              </Col>
                              <Col offset={1} span={8}>
                                <Form.Item
                                  {...field}
                                  shouldUpdate={true}
                                  name={[field.name, 'share']}
                                  rules={[
                                    {
                                      required: pageType?.type !== 'DETAIL',
                                      message: t('required'),
                                    },
                                    () => ({
                                      validator(_, value) {
                                        if (value < 0) {
                                          return Promise.reject(t('minAmount'));
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                  fieldKey={[field.fieldKey, 'share']}>
                                  <InputNumber
                                    disabled={pageType?.disabledAdminShares}
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ',',
                                      )
                                    }
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, '')
                                    }
                                    style={{ borderRadius: 5, width: '100%' }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                span={1}
                                style={{
                                  padding: 5,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                {pageType?.type !== 'DETAIL' &&
                                  !pageType?.disabledAdminShares && (
                                    <MinusCircleOutlined
                                      style={{ fontSize: 16 }}
                                      onClick={() => remove(field.name)}
                                    />
                                  )}
                              </Col>
                            </Row>
                          );
                        })}
                        <Col offset={6} span={18}>
                          <Form.ErrorList errors={errors} />
                        </Col>
                        {pageType?.type !== 'DETAIL' &&
                          !pageType?.disabledAdminShares && (
                            <Col offset={6} span={18}>
                              <Form.Item>
                                <Button
                                  dashed
                                  size="medium"
                                  type="light"
                                  text={t('Add field')}
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                />
                              </Form.Item>
                            </Col>
                          )}
                      </>
                    )}
                  </Form.List>
                </>
              )}
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
                  <Button
                    text={t(
                      pageType?.type === 'APPROVAL' ? 'approve' : 'submit',
                    )}
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BillMasterFormPage;
