import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {TitleComponent} from '../../components';

export const AuthRoute = ({component, layout: Layout, ...rest}) => {
  const {authData} = useSelector((state) => state.auth);
  const isAuthenticated = authData?.token;
  if (!isAuthenticated && rest?.private && rest.path === '/404-not-found') {
    return <Redirect to="/login" />;
  }
  if (isAuthenticated && (rest.path === '/login' || rest.path === '/')) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div>
      <TitleComponent title={rest.title} />
      <Route
        {...rest}
        exact
        render={(props) => {
          if (Layout) {
            return <Layout>{React.createElement(component, props)}</Layout>;
          }
          return React.createElement(component, props);
        }}
      />
    </div>
  );
};
