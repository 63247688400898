import {message} from 'antd';
import Services from '../../../../services';
import {channelToProviderTypes} from '../../action-types';

const {SET_CHANNEL_TO_PROVIDER_LIST} = channelToProviderTypes;

const setChannelToProviderList = (payload) => ({
  type: SET_CHANNEL_TO_PROVIDER_LIST,
  payload,
});

const getListChannelToProvider = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('channel_to_provider', {size: 10000})
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values?.data);
          dispatch(setChannelToProviderList(res.data.values?.data));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createChannelToProvider = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('channel_to_provider', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteChannelToProvider = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`channel_to_provider/${request.channel_to_provider_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

export {
  getListChannelToProvider,
  createChannelToProvider,
  deleteChannelToProvider,
};
