import {message} from 'antd';
import Services from '../../../../services';
import {authActionTypes} from '../../action-types';

const {SET_AUTH_DATA} = authActionTypes;

const setAuthData = (payload) => ({
  type: SET_AUTH_DATA,
  payload,
});

const login = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('user/login', request)
      .then((res) => {
        resolve(res);
        dispatch(setAuthData(res.data.values));
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

export {setAuthData, login};
