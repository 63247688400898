import {tableTypes} from '../../action-types';

const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER_VALUE,
  SET_FILTERED,
  SET_SEARCH_VALUE,
} = tableTypes;

const setPageSize = (payload) => ({
  type: SET_PAGE_SIZE,
  payload,
});
const setPage = (payload) => ({
  type: SET_PAGE,
  payload,
});
const setFilterValue = (payload) => ({
  type: SET_FILTER_VALUE,
  payload,
});
const setFiltered = (payload) => ({
  type: SET_FILTERED,
  payload,
});
const setSearchValue = (payload) => ({
  type: SET_SEARCH_VALUE,
  payload,
});
const resetTableState = () => (dispatch) => {
  dispatch(setPageSize(10));
  dispatch(setPage(1));
  dispatch(setFilterValue(null));
  dispatch(setSearchValue(''));
  dispatch(setFiltered(false));
};

export {
  setPageSize,
  setPage,
  setFilterValue,
  setFiltered,
  setSearchValue,
  resetTableState,
};
