import {penaltyTypes} from '../../action-types';

const {SET_PENALTY_LIST, SET_PENALTY_DETAIL} = penaltyTypes;

const initialState = {
  listPenalty: [],
  detailPenalty: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_PENALTY_LIST:
      return {...state, listPenalty: payload};
    case SET_PENALTY_DETAIL:
      return {...state, detailPenalty: payload};
    default:
      return state;
  }
};

export default reducer;
