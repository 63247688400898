import {
  CloudUploadOutlined,
  DownOutlined,
  FilterFilled,
  UpOutlined,
} from '@ant-design/icons';
import {Badge, Col, Form, Row, Space, Table} from 'antd';
import Moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  resetTableState,
  setFiltered,
  setFilterValue,
  setPage,
  setPageSize,
  setSearchValue,
} from '../../../redux/store/actions/table';
import '../../../styles/table.css';
import {history} from '../../../utilities';
import {Button} from '../../atoms';
import {CustomInput} from '../../molecules';
import {renderFieldFilter} from './mapper';

const CustomTable = ({
  onSearch,
  createColumns,
  dataSource,
  placeholderSearch = 'Search',
  filterBy,
  showButtonUpload,
  uploadPage,
  onFilter,
  filterField,
  resetFilter,
  uploadPageParams,
  onPageChange,
  onSizeChange,
  totalData,
  showSizeChanger = true,
  initialFilterValues,
}) => {
  const dispatch = useDispatch();
  const {loadingTable} = useSelector((state) => state.loading);
  const {page, pageSize, filterValue, filtered, searchValue} = useSelector(
    (state) => state.table,
  );
  const [list, setList] = useState(dataSource);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [form] = Form.useForm();
  const onChangePage = (page, size) => {
    const request = {
      size,
      page,
    };
    dispatch(setPageSize(size));
    dispatch(setPage(page));
    onPageChange && onPageChange(request, filterValue);
  };
  const onChangeSize = (current, size) => {
    const request = {
      size,
      page: current,
    };
    dispatch(setPageSize(size));
    dispatch(setPage(page));
    onSizeChange && onSizeChange(request, filterValue);
  };
  useEffect(() => {
    setList(dataSource);
    if (filterValue) {
      if (filterValue.dateRange) {
        const result = filterValue.dateRange.map((item) => Moment(item));
        form.setFieldsValue({dateRange: result});
      } else {
        form.setFieldsValue(filterValue);
      }
    }
    if (searchValue) {
      filterList(searchValue);
    }
  }, [dataSource]);
  const filterList = (e) => {
    const value = e?.target?.value || e;
    if (!value.length) {
      setList(dataSource);
    } else {
      const result = dataSource.filter((item) => {
        if (filterBy) {
          if (
            filterBy.filter((x) => {
              return item[x]
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            })[0]
          ) {
            return item;
          }
        } else {
          if (
            Object.keys(item).filter((x) => {
              return item[x]
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            })[0]
          ) {
            return item;
          }
        }
      });
      setList(result);
    }
  };
  const onFinish = (values) => {
    onFilter(values);
    dispatch(setPage(1));
    dispatch(setFilterValue(values));
    dispatch(setFiltered(true));
  };
  return (
    <>
      <Row style={{marginBottom: 15}} justify="space-between">
        {filterField?.length ? (
          <Col span={8}>
            <Row>
              <Space
                size="middle"
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {filterField?.length && (
                  <Space
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowFilterSection(!showFilterSection)}>
                    <Badge dot={filtered}>
                      <FilterFilled />
                    </Badge>
                    <div>Filter Data</div>
                    {showFilterSection ? <UpOutlined /> : <DownOutlined />}
                  </Space>
                )}
                {!onPageChange && !onSizeChange && (
                  <CustomInput
                    type="search"
                    placeholder={placeholderSearch}
                    rounded
                    value={searchValue}
                    allowClear={true}
                    onChange={
                      onSearch
                        ? (e) => {
                            onSearch(e);
                            dispatch(setSearchValue(e.target.value));
                          }
                        : (e) => {
                            filterList(e);
                            dispatch(setSearchValue(e.target.value));
                          }
                    }
                  />
                )}
              </Space>
            </Row>
          </Col>
        ) : (
          !onPageChange &&
          !onSizeChange && (
            <Col span={6}>
              <CustomInput
                type="search"
                placeholder={placeholderSearch}
                rounded
                value={searchValue}
                allowClear={true}
                onChange={
                  onSearch
                    ? (e) => {
                        onSearch(e);
                        dispatch(setSearchValue(e.target.value));
                      }
                    : (e) => {
                        filterList(e);
                        dispatch(setSearchValue(e.target.value));
                      }
                }
              />
            </Col>
          )
        )}
        {showButtonUpload && (
          <Button
            onClick={() =>
              history.push({
                pathname: uploadPage,
                state: uploadPageParams,
              })
            }
            icon={<CloudUploadOutlined />}
            text="Upload File"
            rounded
            size="medium"
            type="light"
          />
        )}
      </Row>
      {showFilterSection && (
        <>
          <Form
            onValuesChange={(changedValues, allValues) => {
              form.setFieldsValue(changedValues);
            }}
            form={form}
            initialValues={initialFilterValues}
            name="form-custom-table"
            onFinish={onFinish}>
            <Row style={{marginBottom: 10, marginTop: 10}}>
              {renderFieldFilter(filterField)}
            </Row>
            <Row justify="end" style={{marginBottom: 10, marginTop: 10}}>
              <Col span={4} style={{marginRight: 10}}>
                <Button
                  block
                  disabled={!filtered}
                  onClick={() => {
                    resetFilter();
                    form.resetFields();
                    dispatch(resetTableState());
                  }}
                  text="Reset"
                  rounded
                  size="medium"
                  type="light"
                />
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button
                    block
                    text="Filter"
                    rounded
                    size="medium"
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      <Row style={{borderRadius: 10}}>
        <Col span={24} style={{borderRadius: 10, border: 'solid 2px #EEEFF1'}}>
          <Table
            loading={loadingTable}
            scroll={{x: '500px'}}
            rowClassName={(record, index) =>
              index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
            columns={createColumns()}
            dataSource={list}
            pagination={{
              current: page,
              showSizeChanger,
              onChange: onChangePage,
              onShowSizeChange: onChangeSize,
              pageSize,
              total: totalData,
              showTotal: (total, range) =>
                `Show ${range[0]} - ${range[1]} from ${total} data`,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomTable;
