import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Col,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Slider,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withdrawBalance } from '../../../redux/store/actions/dashboard';
import { changeLanguage } from '../../../redux/store/actions/language';
import { colors, history, LANGUAGE, thousandSeparator } from '../../../utilities';
import { Button } from '../../atoms';
import { CustomInput } from '../../molecules';

const LayoutHeader = ({
  collapsed,
  setCollapsed,
  store,
  ProfilePicture,
  authData,
  balance,
  withdrawalFee
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('header');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const { language } = useSelector((state) => state.language);
  const [form] = Form.useForm();
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onChangeAmount = (e) => {
    setAmount(e);
    form.setFieldsValue({ amount: e });
  };
  const onFinish = (values) => {
    setLoading(true);
    dispatch(withdrawBalance(values))
      .then(() => {
        setVisible(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onFinishFailed = (values) => {
    console.log('failed', values);
  };
  return (
    <Layout.Header
      style={{
        background: '#F0F2F5',
        padding: '1vh',
        paddingLeft: '2vh',
        paddingRight: '3vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      {collapsed ? (
        <MenuUnfoldOutlined
          onClick={() => setCollapsed(false)}
          style={{ fontSize: 25 }}
        />
      ) : (
        <MenuFoldOutlined
          onClick={() => setCollapsed(true)}
          style={{ fontSize: 25 }}
        />
      )}
      <Space>
        <Dropdown
          arrow
          placement="bottomRight"
          trigger={['click']}
          overlay={
            <Menu>
              {LANGUAGE.map((item, index) => (
                <Menu.Item
                  key={index}
                  onClick={() => {
                    dispatch(changeLanguage(item, i18n));
                  }}>
                  <Space align="center" style={{ cursor: 'pointer' }}>
                    <img
                      src={item.icon}
                      style={{ width: 30, height: 20 }}
                      alt="flag-icon"
                    />
                    <div style={{ lineHeight: 'normal' }}>
                      <p
                        style={{
                          fontSize: 14,
                          color: colors.dark,
                          fontWeight: 700,
                          margin: '0 0',
                        }}>
                        {item.label}
                      </p>
                    </div>
                  </Space>
                </Menu.Item>
              ))}
            </Menu>
          }>
          <Space align="center" style={{ cursor: 'pointer' }}>
            <img
              src={language.icon}
              style={{ width: 30, height: 20 }}
              alt="flag-icon"
            />
            <div style={{ lineHeight: 'normal' }}>
              <p
                style={{
                  fontSize: 14,
                  color: colors.dark,
                  fontWeight: 700,
                  margin: '0 0',
                }}>
                {language.label}
              </p>
            </div>
          </Space>
        </Dropdown>
        <Divider
          type="vertical"
          style={{ borderLeft: '1px solid #292b2c', height: '2em' }}
        />
        <Dropdown
          arrow
          placement="bottomRight"
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item
                key={0}
                onClick={() => message.info(t('underDevelopment'))}>
                <Row>
                  <Col span={4}>
                    <SettingOutlined />
                  </Col>
                  <Col span={20}>
                    <div>{t('setting')}</div>
                  </Col>
                </Row>
              </Menu.Item>
              {authData.roles !== 'super_admin' && (
                <Menu.Item key={1} onClick={() => showDrawer()}>
                  <Row>
                    <Col span={4}>
                      <MoneyCollectOutlined />
                    </Col>
                    <Col span={20}>
                      <div>{t('withdraw')}</div>
                    </Col>
                  </Row>
                </Menu.Item>
              )}
              <Menu.Divider />
              <Menu.Item
                danger
                key={2}
                onClick={() => {
                  store.dispatch({ type: 'USER_LOGGED_OUT' });
                  history.push('/login');
                }}>
                <Row>
                  <Col span={4}>
                    <LogoutOutlined />
                  </Col>
                  <Col span={20}>
                    <div>{t('logout')}</div>
                  </Col>
                </Row>
              </Menu.Item>
            </Menu>
          }>
          <Space align="center" style={{ cursor: 'pointer' }}>
            <div style={{ lineHeight: 'normal' }}>
              <p
                style={{
                  fontSize: 14,
                  color: colors.dark,
                  fontWeight: 700,
                  margin: '0 0',
                }}>
                {authData?.username}
              </p>
              {authData.roles !== 'super_admin' && (
                <p
                  style={{
                    margin: '0 0',
                    fontSize: 12,
                    fontWeight: 700,
                    color: colors.danger,
                  }}>
                  {t('balance', {
                    balance: thousandSeparator(balance?.balance || '' || 0, 'Rp '),
                  })}
                </p>
              )}
            </div>
            <img
              src={ProfilePicture}
              style={{ width: 40, height: 40 }}
              alt="profile_picture"
            />
            <DownOutlined />
          </Space>
        </Dropdown>
      </Space>
      <Drawer
        title={t('withdrawTitle')}
        placement="right"
        onClose={onClose}
        visible={visible}>
        <Form
          name="withdraw"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            label={t('amount')}
            name="amount"
            labelCol={{ span: 24 }}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: t('required'),
              },
              () => ({
                validator(_, value) {
                  if (value <= 0) {
                    return Promise.reject(t('minAmount'));
                  }
                  return Promise.resolve();
                },
              }),
              () => ({
                validator(_, value) {
                  if (value > Number(balance?.balance || '')) {
                    return Promise.reject(t('maxAmount'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
            <CustomInput
              disabled={Number(balance?.balance || '') === 0}
              onChange={onChangeAmount}
              type="money"
              value={amount}
            />
          </Form.Item>
          <Slider
            value={amount}
            max={balance?.balance || ''}
            disabled={Number(balance?.balance || '') === 0}
            onChange={onChangeAmount}
          />
          <Row justify="end">
            <p
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: colors.danger,
              }}>
              {thousandSeparator(balance?.balance || '', 'Rp ')}
            </p>
          </Row>
          <Form.Item>
            <Button
              type="secondary"
              block
              text={t('withdraw')}
              htmlType="submit"
              loading={loading}
              disabled={Number(balance?.balance || '') === 0 || (Number(amount) - Number(balance.withdrawal_fee) - 30000 < 1)}
            />
          </Form.Item>
          <Row justify="end">
            <p
              style={{
                fontSize: 12,
                fontWeight: 400
              }}>
              Pencairan akan dikenakan admin sebesar Rp {thousandSeparator(Number(balance.withdrawal_fee))}
            </p>
          </Row>

          {(Number(amount) - Number(balance.withdrawal_fee) - 30000 > 0) 
          ? 
          <Row justify="end">
            <p
              style={{
                fontSize: 12,
                fontWeight: 400
              }}>
              Dana yang akan Anda terima adalah Rp {thousandSeparator(Number(amount) - Number(balance.withdrawal_fee))}
            </p>
          </Row> 
          :
          <Row justify="end">
            <p
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: colors.danger
              }}>
              Nominal pencairan harus diatas {thousandSeparator(Number(process.env.REACT_APP_WITHDRAWAL_FEE) + 30000)}
            </p>
          </Row>
          }

          
        </Form>
      </Drawer>
    </Layout.Header>
  );
};

export default LayoutHeader;
