import {UpCircleFilled} from '@ant-design/icons';
import {BackTop, Layout, Menu} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import {store} from '../../../App';
import {IkiBillWhiteLogo, ProfilePicture} from '../../../assets';
import {getBalance} from '../../../redux/store/actions/dashboard';
import {resetTableState} from '../../../redux/store/actions/table';
import '../../../styles/layout.css';
import {
  colors,
  PROVIDER,
  SIDER_MENU_LIST,
  SUPER_ADMIN,
  USER,
} from '../../../utilities';
import {SpinnerLoading} from '../../atoms';
import LayoutHeader from './LayoutHeader';

const {SubMenu} = Menu;
const {Sider, Content, Footer} = Layout;

const LayoutTemplate = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {authData} = useSelector((state) => state.auth);
  const {balance} = useSelector((state) => state.dashboard);
  const [menu, setMenu] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    if (authData.roles !== 'super_admin') {
      dispatch(getBalance());
    }
    switch (authData.roles) {
      case 'user':
        return setMenu(USER);
      case 'super_admin':
        return setMenu(SUPER_ADMIN);
      case 'provider':
        return setMenu(PROVIDER);
      default:
        return setMenu([]);
    }
  }, []);
  useEffect(() => {
    let selectedMenu = SIDER_MENU_LIST.filter((item) => {
      if (item?.path) {
        return (
          item.path === location.pathname || item.key === location.state?.key
        );
      }
    });
    let selectedChildMenu = [];
    let openKeyMenu;
    if (!selectedMenu.length) {
      openKeyMenu = SIDER_MENU_LIST.filter((item) => {
        if (item?.child) {
          if (
            item.child.filter(
              (item) =>
                item.path === location.pathname ||
                item.key === location.state?.key,
            ).length
          ) {
            return true;
          }
        }
      })[0];
      selectedChildMenu = openKeyMenu?.child.filter(
        (item) =>
          item.path === location.pathname || item.key === location.state?.key,
      );
    }
    if (location.pathname === '' || location.pathname === '/') {
      selectedMenu = [{key: 'DASHBOARD'}];
      selectedChildMenu = [];
    }
    if (selectedChildMenu?.length) {
      setSelectedKeys([selectedChildMenu[0]?.key]);
      setOpenKeys([openKeyMenu.key]);
    }
    if (!selectedChildMenu?.length) {
      setSelectedKeys([selectedMenu[0]?.key]);
      setOpenKeys([]);
    }
  }, [location]);

  const onOpenChange = (key) => {
    setOpenKeys([key[key.length - 1]]);
  };
  const onClickMenu = () => {
    dispatch(resetTableState());
  };

  const openKeyAttr = openKeys.length
    ? {openKeys}
    : {defaultOpenKeys: openKeys};
  return (
    <Layout style={{minHeight: '100vh'}}>
      <Sider
        trigger={null}
        style={{
          background: '#001529',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}>
        <div
          style={{
            textAlign: 'center',
            padding: '5vh 0',
            verticalAlign: 'center',
          }}>
          <img
            src={IkiBillWhiteLogo}
            alt="logo"
            style={{height: collapsed ? '6vh' : '8vh'}}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          {...openKeyAttr}>
          {SIDER_MENU_LIST.filter((x) => {
            if (menu.some((item) => item === x.key)) {
              return x;
            }
          }).map((item) => {
            if (item?.child) {
              return (
                <SubMenu key={item.key} title={item.menu} icon={item.icon}>
                  {item.child.map((childItem) => {
                    return (
                      <Menu.Item
                        key={childItem.key}
                        icon={childItem.icon}
                        onClick={() => onClickMenu()}>
                        <NavLink to={childItem.path}>{childItem.menu}</NavLink>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => onClickMenu()}>
                <NavLink to={item.path}>{item.menu}</NavLink>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{marginLeft: collapsed ? 75 : 200}}>
        <LayoutHeader
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          store={store}
          ProfilePicture={ProfilePicture}
          authData={authData}
          balance={balance}
        />
        <SpinnerLoading>
          <Content
            style={{
              margin: '24px 16px 0',
              overflow: 'initial',
            }}>
            <div className="site-layout-background" style={{padding: 24}}>
              <BackTop visibilityHeight="100vh">
                <UpCircleFilled style={{fontSize: 35, color: colors.dark}} />
              </BackTop>
              {props.children}
            </div>
          </Content>
        </SpinnerLoading>
        <Footer style={{textAlign: 'center'}}>
          Design ©2020 Created by PT. Dunia Bayar Indonesia
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutTemplate;
