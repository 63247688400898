import {usersTypes} from '../../action-types';
import Services from '../../../../services';
import {message} from 'antd';
import {isEmptyObject, removeFirstZero} from '../../../../utilities';
var fileDownload = require('js-file-download');

const {SET_USERS_LIST, SET_USERS_DETAIL, SET_LIST_BANK} = usersTypes;

const setUsersList = (payload) => ({
  type: SET_USERS_LIST,
  payload,
});
const setListBank = (payload) => ({
  type: SET_LIST_BANK,
  payload,
});

const getListUsers = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('user', {size: 10000})
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values?.data);
          dispatch(setUsersList(res.data.values.data));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const getListBank = (showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('bank')
      .then((res) => {
        if (res.data.values) {
          const result = res.data.values.data.map((item) => ({
            value: `${item.bank_code} - ${item.bank_name}`,
            label: `${item.bank_code} - ${item.bank_name}`,
          }));
          resolve(result);
          dispatch(setListBank(result));
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const createUsers = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Post('user', request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const deleteUsers = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Delete(`user/${request.user_id}`)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const updateUsers = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Put(`user/${request.user_id}`, request)
      .then((res) => {
        if (res.data.values) {
          resolve(res.data.values);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailUsers = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (!isEmptyObject(data)) {
      const result = {
        user_id: data.user_id,
        email: data.email,
        fullName: data.name,
        mobilePhone: removeFirstZero(data.mobile),
        description: data.description,
        picName: data.pic_name,
        contractFile: data.contract_file,
        bankAccountName: `${data.bank_code} - ${data.bank_name}`,
        bankAccountHolder: data.bank_account_holder,
        bankAccountNo: data.bank_account_no,
        autoWithdraw: data.auto_withdraw,
      };
      resolve(result);
      dispatch({
        type: SET_USERS_DETAIL,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_USERS_DETAIL,
        payload: {},
      });
    }
  });
};

const getDetailUsers = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`user/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          dispatch(setDetailUsers(data));
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const getFile = (fileName, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`file/${fileName}`)
      .then((res) => {
        resolve(res);
        fileDownload(res.data, fileName);
      })
      .catch((err) => {
        reject(err);
        message.error(err?.response?.data?.message || err.message);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

export {
  getListUsers,
  createUsers,
  updateUsers,
  deleteUsers,
  setDetailUsers,
  getListBank,
  getFile,
  getDetailUsers,
};
