import Services from '../../../../services';
import {uploadTypes} from '../../action-types';
import Moment from 'moment';
import {message} from 'antd';

const {SET_UPLOAD_LIST, SET_UPLOAD_DETAIL, SET_UPLOAD_LIST_TOTAL} = uploadTypes;

const setUploadList = (payload) => ({
  type: SET_UPLOAD_LIST,
  payload,
});
const setUploadTotal = (payload) => ({
  type: SET_UPLOAD_LIST_TOTAL,
  payload,
});
const setUploadDetail = (payload) => ({
  type: SET_UPLOAD_DETAIL,
  payload,
});

const getListUpload = (request, showLoading, dismissLoading) => (dispatch) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get('upload', request)
      .then((res) => {
        if (res.data.values) {
          const {data, totalData} = res.data.values;
          resolve(data);
          dispatch(setUploadList(data));
          dispatch(setUploadTotal(totalData));
        } else {
          dispatch(setUploadList([]));
          reject(res);
        }
      })
      .catch((err) => {
        dispatch(setUploadList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const getUpload = (request, translate, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`upload/${request.id}`)
      .then((res) => {
        if (res.data.values) {
          const data = res.data.values;
          const result = {
            ...data,
            process_date: Moment(data.process_date),
            upload_date: Moment(data.upload_date),
            status: data.is_processed
              ? translate('processed')
              : translate('unprocessed'),
          };
          resolve(result);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        dismissLoading && dispatch(dismissLoading());
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      });
  });
};

const getDetailUpload = (request, translate, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    dispatch(getUpload(request, translate, showLoading, dismissLoading))
      .then((resUpload) => {
        Services.Get(`upload/${request.id}/detail`)
          .then((res) => {
            if (res.data.values) {
              const result = {
                ...resUpload,
                data: res.data.values.data.map((item) => {
                  Object.keys(JSON.parse(item.rows)).forEach((x) => {
                    Object.assign(item, {[x]: JSON.parse(item.rows)[x]});
                  });
                  return item;
                }),
              };
              resolve(result);
              dispatch(setUploadDetail(result));
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            message.error(err?.response?.data?.message || err.message);
            reject(err);
          })
          .finally(() => {
            dismissLoading && dispatch(dismissLoading());
          });
      })
      .catch((err) => {
        dismissLoading && dispatch(dismissLoading());
        message.error(err?.response?.data?.message || err.message);
        reject(err);
      });
  });
};

export {getListUpload, getDetailUpload, setUploadDetail};
