import React from 'react';
import {Helmet} from 'react-helmet';

const TitleComponent = ({title}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Dashboard Website for AiYO Bill Administrator created using React JS"
      />
      <title>
        {title ? `AiYO Bill Dashboard - ${title}` : 'AiYO Bill Dashboard'}
      </title>
    </Helmet>
  );
};

export default TitleComponent;
