import {languageTypes} from '../../action-types';

const {SET_LANGUAGE} = languageTypes;

export const changeLanguage = (language, i18n) => (dispatch) => {
  return new Promise((resolve, reject) => {
    i18n.changeLanguage(language.value);
    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    });
    resolve(language);
  });
};
