import {Col, DatePicker, Input, InputNumber, Form, Row, Select} from 'antd';
import Moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Container, CustomInput, PageTitle, CustomForm} from '../../components';
import {billActions, usersActions} from '../../redux/store/actions';
import {dismissLoading, showLoading} from '../../redux/store/actions/loading';

const {setDetailBill, getDetailBill} = billActions;
const {getListUsers} = usersActions;

const BillDetailPage = () => {
  const dispatch = useDispatch();
  const {detailBill} = useSelector((state) => state.bill);
  const {listUsers} = useSelector((state) => state.users);
  const {authData} = useSelector((state) => state.auth);
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingUsers, setLoadingUsers] = useState(false);
  const {t} = useTranslation('billDetailPage');
  useEffect(() => {
    const request = {id: location.state.id, role: authData.roles};
    dispatch(getDetailBill(request, showLoading, dismissLoading));
    return () => {
      dispatch(setDetailBill({}));
    };
  }, []);
  useEffect(() => {
    if (location?.state) {
      switch (location.state?.type) {
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-bill-',
            disabled: true,
            disabledAdminShares: true,
          });
          break;
        default:
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-bill-',
            disabled: true,
            disabledAdminShares: true,
          });
          break;
      }
    }
    if (authData.roles === 'super_admin') {
      getUsers();
    }
  }, [location.state, t]);
  const getUsers = () => {
    setLoadingUsers(true);
    dispatch(getListUsers()).finally(() => {
      setLoadingUsers(false);
    });
  };
  const userListMapper = (list) => {
    const result = list.map((item) => ({
      value: item.user_id,
      label: item.name,
    }));
    return result;
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm name={pageType?.form} defaultValues={detailBill}>
              <Form.Item
                name="bill_master_name"
                label={t('billName')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="bill_amount"
                label={t('billAmount')}
                labelCol={{span: 6}}
                labelAlign="left">
                <CustomInput type="money" disabled={pageType?.disabled} />
              </Form.Item>
              <Form.Item
                name="client_name"
                label={t('clientName')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="client_mobile"
                label={t('clientMobile')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="client_address"
                label={t('clientAddress')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="bill_date"
                label={t('billDate')}
                labelCol={{span: 6}}
                labelAlign="left">
                <DatePicker
                  disabled={pageType?.disabled}
                  initialValue={Moment()}
                  style={{borderRadius: 5, width: '100%'}}
                />
              </Form.Item>
              <Form.Item
                name="bill_due_date"
                label={t('billDueDate')}
                labelCol={{span: 6}}
                labelAlign="left">
                <DatePicker
                  initialValue={Moment()}
                  disabled={pageType?.disabled}
                  style={{borderRadius: 5, width: '100%'}}
                />
              </Form.Item>
              <Form.Item
                name="admin_type"
                label={t('adminType')}
                labelCol={{span: 6}}
                labelAlign="left">
                <CustomInput
                  disabled={pageType?.disabled}
                  name="admin_type"
                  type="select"
                  source={[
                    {value: 'include', label: t('include')},
                    {value: 'exclude', label: t('exclude')},
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="admin_amount"
                label={t('adminAmount')}
                labelCol={{span: 6}}
                labelAlign="left">
                <CustomInput disabled={pageType?.type !== 'ADD'} type="money" />
              </Form.Item>
              <Form.Item
                name="admin_amount"
                label={t('adminAmount')}
                labelCol={{span: 6}}
                labelAlign="left">
                <CustomInput disabled={pageType?.type !== 'ADD'} type="money" />
              </Form.Item>
              {detailBill?.admin_shares?.length &&
                authData.roles === 'super_admin' && (
                  <>
                    <Row>
                      <Col span={6}>
                        <label>{t('adminShare')}</label>
                      </Col>
                      <Col span={8}>
                        <label>{t('userName')}</label>
                      </Col>
                      <Col offset={1} span={9}>
                        <label>{t('shareAmount')}</label>
                      </Col>
                    </Row>
                    <Form.List name="admin_shares">
                      {(fields, {add, remove}, {errors}) => (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Row key={index}>
                                <Col span={6} />
                                <Col span={8}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'user_id']}
                                    fieldKey={[field.fieldKey, 'user_id']}>
                                    <Select
                                      showSearch
                                      filterOption={(input, option) =>
                                        option?.label
                                          ?.toLowerCase()
                                          .indexOf(input?.toLowerCase()) >= 0
                                      }
                                      className="border-normal"
                                      loading={loadingUsers}
                                      options={userListMapper(listUsers)}
                                      disabled={pageType?.disabledAdminShares}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                  <Form.Item
                                    {...field}
                                    shouldUpdate={true}
                                    name={[field.name, 'share']}
                                    fieldKey={[field.fieldKey, 'share']}>
                                    <InputNumber
                                      disabled={pageType?.disabledAdminShares}
                                      formatter={(value) =>
                                        `${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ',',
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\$\s?|(,*)/g, '')
                                      }
                                      style={{borderRadius: 5, width: '100%'}}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      )}
                    </Form.List>
                  </>
                )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BillDetailPage;
