import {uploadTypes} from '../../action-types';

const {SET_UPLOAD_LIST, SET_UPLOAD_DETAIL, SET_UPLOAD_LIST_TOTAL} = uploadTypes;

const initialState = {
  listUpload: [],
  detailUpload: {},
  totalUpload: '',
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_UPLOAD_LIST:
      return {...state, listUpload: payload};
    case SET_UPLOAD_DETAIL:
      return {...state, detailUpload: payload};
    case SET_UPLOAD_LIST_TOTAL:
      return {...state, totalUpload: payload};
    default:
      return state;
  }
};

export default reducer;
