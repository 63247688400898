import {EyeOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {loadingActions, uploadActions} from '../../redux/store/actions';
import {colors, compare, history} from '../../utilities';

const {showLoadingTable, dismissLoadingTable} = loadingActions;
const {getListUpload} = uploadActions;

const UploadPage = () => {
  const dispatch = useDispatch();
  const {listUpload, totalUpload} = useSelector((state) => state.upload);
  const {page, pageSize} = useSelector((state) => state.table);

  const {t} = useTranslation('uploadPage');
  useEffect(() => {
    getList({page, size: pageSize});
  }, [dispatch]);
  const getList = (req) => {
    dispatch(getListUpload(req, showLoadingTable, dismissLoadingTable));
  };
  const createColumns = () => {
    return [
      {
        title: t('fileName'),
        dataIndex: 'filename',
        key: 'filename',
        width: '35%',
        sorter: (a, b) => compare(a, b, 'filename'),
      },
      {
        title: t('status'),
        dataIndex: 'is_processed',
        key: 'is_processed',
        width: '30%',
        render: (item) => {
          switch (item) {
            case true:
              return (
                <div style={{color: colors.secondary, fontWeight: 700}}>
                  {t('processed')}
                </div>
              );
            case false:
              return (
                <div style={{color: colors.danger, fontWeight: 700}}>
                  {t('unprocessed')}
                </div>
              );
            default:
              return item;
          }
        },
        sorter: (a, b) => a.is_processed - b.is_processed,
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '35%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/upload/detail',
                      state: {
                        type: 'DETAIL',
                        key: 'UPLOAD',
                        id: item.upload_id,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle title={t('title')} />
      <Container>
        <CustomTable
          onFilter={getList}
          onPageChange={getList}
          onSizeChange={getList}
          resetFilter={getList}
          createColumns={createColumns}
          dataSource={listUpload}
          totalData={totalUpload}
        />
      </Container>
    </div>
  );
};

export default UploadPage;
