import {transactionJournalTypes} from '../../action-types';

const {
  SET_TRANSACTION_JOURNAL_LIST,
  SET_TRANSACTION_JOURNAL_DETAIL,
  SET_TRANSACTION_JOURNAL_LIST_TOTAL,
} = transactionJournalTypes;

const initialState = {
  totalTransactionJournal: '',
  listTransactionJournal: [],
  detailTransactionJournal: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_TRANSACTION_JOURNAL_LIST:
      return {...state, listTransactionJournal: payload};
    case SET_TRANSACTION_JOURNAL_DETAIL:
      return {...state, detailTransactionJournal: payload};
    case SET_TRANSACTION_JOURNAL_LIST_TOTAL:
      return {...state, totalTransactionJournal: payload};
    default:
      return state;
  }
};

export default reducer;
