import {DeleteFilled, EditFilled, EyeOutlined} from '@ant-design/icons';
import {Col, message, Popconfirm, Row} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Container, CustomTable, PageTitle} from '../../components';
import {loadingActions, providerActions} from '../../redux/store/actions';
import {
  deleteProvider,
  setDetailProvider,
} from '../../redux/store/actions/provider';
import {compare, history} from '../../utilities';

const {showLoadingTable, dismissLoadingTable} = loadingActions;
const {getListProvider} = providerActions;

const ProviderPage = () => {
  const dispatch = useDispatch();
  const {listProvider, totalProvider} = useSelector((state) => state.provider);
  const {authData} = useSelector((state) => state.auth);
  const showButton = authData.roles === 'super_admin';
  const {t} = useTranslation('providerPage');
  useEffect(() => {
    getList(null);
  }, []);
  const getList = (req) => {
    dispatch(getListProvider(req, showLoadingTable, dismissLoadingTable));
  };
  const onClickDelete = (req) => {
    dispatch(deleteProvider(req, showLoadingTable, dismissLoadingTable))
      .then((res) => {
        message.success(t('deleteSuccess', {providerName: req.name}));
        dispatch(getListProvider(null, null, dismissLoadingTable));
      })
      .catch((err) => {
        message.error(t('deleteFailed', {providerName: req.name}));
      });
  };
  const createColumns = () => {
    return [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => compare(a, b, 'name'),
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => compare(a, b, 'description'),
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: 'action',
        width: '35%',
        render: (index, item) => {
          return (
            <Row>
              <Col xs={24} md={24} lg={24} xl={8}>
                <Button
                  icon={<EyeOutlined />}
                  text={t('buttonDetail')}
                  type="light"
                  rounded
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: '/provider/detail',
                      state: {
                        type: 'DETAIL',
                        key: 'PROVIDER',
                        id: item.provider_id,
                      },
                    });
                  }}
                />
              </Col>
              {showButton && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Button
                    icon={<EditFilled />}
                    text={t('buttonEdit')}
                    type="light"
                    rounded
                    size="small"
                    onClick={() => {
                      history.push({
                        pathname: '/provider/edit',
                        state: {
                          type: 'EDIT',
                          key: 'PROVIDER',
                          id: item.provider_id,
                        },
                      });
                    }}
                  />
                </Col>
              )}
              {showButton && (
                <Col xs={24} md={24} lg={24} xl={8}>
                  <Popconfirm
                    title={t('deleteConfirm', {providerName: item.name})}
                    okText={t('yes')}
                    onConfirm={() => onClickDelete(item)}
                    cancelText={t('no')}>
                    <Button
                      icon={<DeleteFilled />}
                      text={t('buttonDelete')}
                      type="light"
                      rounded
                      size="small"
                    />
                  </Popconfirm>
                </Col>
              )}
            </Row>
          );
        },
      },
    ];
  };
  return (
    <div>
      <PageTitle
        title={t('title')}
        showButtonAdd={showButton}
        buttonAddText={t('buttonAdd')}
        buttonAddPages={{
          page: '/provider/add',
          params: {type: 'ADD', key: 'PROVIDER'},
        }}
      />
      <Container>
        <CustomTable
          createColumns={createColumns}
          dataSource={listProvider}
          placeholderSearch={t('placeholderSearch')}
          filterBy={['name', 'description']}
        />
      </Container>
    </div>
  );
};

export default ProviderPage;
