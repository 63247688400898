import {Col, Form, Input, message, Row, Checkbox} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Button,
  Container,
  CustomForm,
  CustomInput,
  PageTitle,
} from '../../components';
import {loadingActions, usersActions} from '../../redux/store/actions';
import {history, phoneNumberNormalize} from '../../utilities';

const {
  getListBank,
  createUsers,
  getDetailUsers,
  setDetailUsers,
  updateUsers,
} = usersActions;
const {
  dismissLoadingButton,
  showLoadingButton,
  dismissLoading,
  showLoading,
} = loadingActions;

const UserFormPage = () => {
  const dispatch = useDispatch();
  const {listBank, detailUsers} = useSelector((state) => state.users);
  const [form] = Form.useForm();
  const location = useLocation();
  const [pageType, setPageType] = useState({});
  const [loadingBank, setLoadingBank] = useState(false);
  const {t} = useTranslation('userFormPage');
  useEffect(() => {
    if (location.state) {
      if (location.state?.type !== 'ADD') {
        const request = {id: location?.state?.id};
        dispatch(getDetailUsers(request, showLoading, dismissLoading));
      }
      switch (location.state?.type) {
        case 'ADD':
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
            form: 'add-user',
          });
          break;
        case 'DETAIL':
          setPageType({
            title: t('detailTitle'),
            type: 'DETAIL',
            form: 'detail-user',
            disabled: true,
          });
          break;
        case 'EDIT':
          setPageType({
            title: t('editTitle'),
            type: 'EDIT',
            form: 'edit-user',
          });
          break;
        default:
          setPageType({
            title: t('addTitle'),
            type: 'ADD',
          });
          break;
      }
    } else {
      history.push('/user');
    }
    getBank();
    return () => {
      dispatch(setDetailUsers({}));
    };
  }, [location.state, t]);
  const getBank = () => {
    setLoadingBank(true);
    dispatch(getListBank()).finally(() => {
      setLoadingBank(false);
    });
  };
  const onFinish = (values) => {
    console.log('values', values);
    const request = {
      ...values,
      user_id: detailUsers.user_id,
      mobilePhone: `0${values.mobilePhone}`,
      bankAccountName: values?.bankAccountName.split(' - ')[1],
      bankCode: values?.bankAccountName.split(' - ')[0],
    };
    if (pageType.type === 'ADD') {
      dispatch(
        createUsers(request, showLoadingButton, dismissLoadingButton),
      ).then(() => {
        message.success(t('success'));
        history.push('/user');
      });
    } else {
      dispatch(
        updateUsers(request, showLoadingButton, dismissLoadingButton),
      ).then(() => {
        message.success(t('editSuccess', {name: request.fullName}));
        history.push('/user');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div>
      <PageTitle title={pageType?.title} showBackButton />
      <Container>
        <Row justify="center">
          <Col span={18}>
            <CustomForm
              name={pageType?.form}
              formRef={form}
              defaultValues={detailUsers}
              onSubmit={onFinish}
              onSubmitFailed={onFinishFailed}>
              <Form.Item
                name="fullName"
                label={t('name')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={t('email')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                  {
                    type: 'email',
                    message: t('emailValidation'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.type !== 'ADD'}
                />
              </Form.Item>
              <Form.Item
                name="mobilePhone"
                label={t('mobilePhone')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                  {
                    transform: (value) => Number(value),
                    type: 'number',
                    message: t('numberValidation'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                  addonBefore={'+62'}
                  onChange={(e) => {
                    form.setFieldsValue({
                      mobilePhone: phoneNumberNormalize(e.target.value),
                    });
                  }}
                />
              </Form.Item>
              {pageType?.type === 'ADD' && (
                <Form.Item
                  name="roles"
                  label={t('roles')}
                  labelCol={{span: 6}}
                  labelAlign="left"
                  rules={[
                    {
                      required: pageType?.type !== 'DETAIL',
                      message: t('required'),
                    },
                    {
                      whitespace: true,
                      message: t('required'),
                    },
                  ]}>
                  <CustomInput
                    disabled={pageType?.disabled}
                    name="roles"
                    type="select"
                    source={[
                      {label: 'Provider', value: 'provider'},
                      {label: 'User', value: 'user'},
                    ]}
                    loading={loadingBank}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="bankAccountName"
                label={t('bankAccountName')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <CustomInput
                  disabled={pageType?.disabled}
                  name="bankAccountName"
                  type="select"
                  source={listBank}
                  loading={loadingBank}
                />
              </Form.Item>
              <Form.Item
                name="bankAccountHolder"
                label={t('bankAccountHolder')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="bankAccountNo"
                label={t('bankAccountNo')}
                labelCol={{span: 6}}
                labelAlign="left"
                rules={[
                  {
                    required: pageType?.type !== 'DETAIL',
                    message: t('required'),
                  },
                  {
                    whitespace: true,
                    message: t('required'),
                  },
                  {
                    transform: (value) => Number(value),
                    type: 'number',
                    message: t('numberValidation'),
                  },
                ]}>
                <Input
                  style={{borderRadius: 5}}
                  disabled={pageType?.disabled}
                />
              </Form.Item>
              <Form.Item
                name="autoWithdraw"
                valuePropName="checked"
                label={t('autoWithdraw')}
                labelCol={{span: 6}}
                labelAlign="left">
                <Checkbox disabled={pageType?.disabled} />
              </Form.Item>
              {pageType.type !== 'DETAIL' && (
                <Form.Item wrapperCol={{span: 4, offset: 20}}>
                  <Button
                    text={t('submit')}
                    block
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              )}
            </CustomForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserFormPage;
