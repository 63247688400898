import {message} from 'antd';
import Services from '../../../../services';
import {transactionJournalTypes} from '../../action-types';

const {
  SET_TRANSACTION_JOURNAL_LIST,
  SET_TRANSACTION_JOURNAL_DETAIL,
  SET_TRANSACTION_JOURNAL_LIST_TOTAL,
} = transactionJournalTypes;

const setTransactionJournalList = (payload) => ({
  type: SET_TRANSACTION_JOURNAL_LIST,
  payload,
});
const setTransactionJournalTotal = (payload) => ({
  type: SET_TRANSACTION_JOURNAL_LIST_TOTAL,
  payload,
});

const getListTransactionJournal = (request, showLoading, dismissLoading) => (
  dispatch,
) => {
  showLoading && dispatch(showLoading());
  return new Promise((resolve, reject) => {
    Services.Get(`balance_journal`, request)
      .then((res) => {
        if (res.data.values) {
          const {data, totalData} = res.data.values;
          resolve(res.data.values.data);
          dispatch(setTransactionJournalList(data));
          dispatch(setTransactionJournalTotal(totalData));
        } else {
          dispatch(setTransactionJournalList([]));
          reject(res);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || err.message);
        dispatch(setTransactionJournalList([]));
        reject(err);
      })
      .finally(() => {
        dismissLoading && dispatch(dismissLoading());
      });
  });
};

const setDetailTransactionJournal = (id, list, showLoading, dismissLoading) => (
  dispatch,
) => {
  return new Promise((resolve, reject) => {
    if (id && list) {
      let result = list.filter((item) => item.balance_journal_id === id)[0];
      if (result) {
        resolve(result);
        dispatch({
          type: SET_TRANSACTION_JOURNAL_DETAIL,
          payload: result,
        });
      } else {
        reject(result);
      }
    } else {
      dispatch({
        type: SET_TRANSACTION_JOURNAL_DETAIL,
        payload: {},
      });
    }
  });
};
export {getListTransactionJournal, setDetailTransactionJournal};
