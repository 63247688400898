import {Form, Input, Modal} from 'antd';
import React from 'react';
import {Button, SpinnerLoading} from '../../atoms';

const BillPaymentModal = ({
  visible,
  setVisible,
  initialValues,
  onFinish,
  onFinishFailed,
  t,
}) => {
  return (
    <Modal
      title={t('billPayment')}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          text={t('cancel')}
          type="light"
          onClick={() => setVisible(false)}
        />,
      ]}>
      <SpinnerLoading>
        <Form
          name="bill-payment"
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            name="bill_master_name"
            label={t('billMasterName')}
            labelCol={{span: 7}}
            labelAlign="left">
            <Input style={{borderRadius: 5}} disabled={true} />
          </Form.Item>
          <Form.Item
            name="bill_amount"
            label={t('billAmount')}
            labelCol={{span: 7}}
            labelAlign="left">
            <Input style={{borderRadius: 5}} disabled={true} />
          </Form.Item>
          <Form.Item
            name="bill_date"
            label={t('billDate')}
            labelCol={{span: 7}}
            labelAlign="left">
            <Input style={{borderRadius: 5}} disabled={true} />
          </Form.Item>
          <Form.Item
            name="bill_due_date"
            label={t('billDueDate')}
            labelCol={{span: 7}}
            labelAlign="left">
            <Input style={{borderRadius: 5}} disabled={true} />
          </Form.Item>
          <Form.Item
            name="remarks"
            label={t('remarks')}
            labelCol={{span: 7}}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: t('required'),
              },
              {
                whitespace: true,
                message: t('required'),
              },
            ]}>
            <Input.TextArea style={{borderRadius: 5}} />
          </Form.Item>
          <Form.Item>
            <Button
              text={t('payBill')}
              block
              type="primary"
              htmlType="submit"
            />
          </Form.Item>
        </Form>
      </SpinnerLoading>
    </Modal>
  );
};

export default BillPaymentModal;
